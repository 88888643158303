import { Box, Button } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { borrarPlaces } from "../../../Redux/actions/actionDataCountry";
import { borrarDataRequest } from "../../../Redux/actions/ActionDataForm";
import { RemoveActiveStep, RemoveGlobalDataFormValues } from "../../../Redux/actions/ActionGlobalState";
import { removeError } from "../../../Redux/actions/UIError";
import { useStyles } from "../FormMenores.styles";

export const Step4 = () => {
  const classes = useStyles();
  const dispatch = useDispatch()

  const { statusCode } = useSelector((state) => state.FormData)
  const { msgError } = useSelector((state) => state.uiError)

  const handleInitioClick = () => {
    dispatch(RemoveGlobalDataFormValues());
    dispatch(borrarPlaces());
    dispatch(removeError());
    dispatch(RemoveActiveStep());
    dispatch(borrarDataRequest());
  };

  return (
    <>
      {
        statusCode ?
        <Box className={classes.alert_margin}>
          <Alert severity="info">
            <AlertTitle className={classes.text_width}>
              Gracias por completar sus datos. Revise su correo electrónico para
              conocer los próximos pasos de verificación (compruebe también en su
              carpeta de Spam).
              <br />
              <br />
              En caso de no recibir nuestro mensaje en los próximos 5 minutos,
              contactarnos a través de la dirección:
              <span className={classes.wrap}>
                soporte.certificado@vacunate.gob.do{" "}
              </span>{" "}
              para hacernos saber que completó el formulario de Solicitud y no
              recibió el correo con las instrucciones de verificación.
            </AlertTitle>
          </Alert>
        </Box>
        :
        <Alert severity="error">
          <AlertTitle>{msgError}</AlertTitle>
        </Alert>
      }
      <Box className={classes.box_inicio}>
        <Link to="/" className={classes.link_2}>
          <Button
            variant="contained"
            onClick={handleInitioClick}
            className={classes.link_button}
          >
            Ir al inicio
          </Button>
        </Link>
      </Box>
    </>
  );
};
