import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    color: "white",
    padding: "26px",
    "@media screen and (max-width: 412px)": {
      paddingLeft: "5px",
      paddingRight: "5px",
    },
  },
  link_2: {
    textDecoration: "none",
  },
  link_text: {
    marginTop: "2rem",
  },
  logo: {
    width: "100%",
    padding: "3rem 0 1rem 0",
  },
  title: {
    fontWeight: "bold",
    marginBottom: "1rem",
    color: "#00205b",
  },

  redLetter: {
    color: "#fe0000",
    fontWeight: "bold",
    marginBottom: "1rem",
    marginTop: "-1rem",
  },
  borderStyle:{
    border: "1px solid #80808081"
  },


  sub_title: {
    fontWeight: "bold",
    marginBottom: "1rem",
    color: "#00205b",
    textAlign: "center",
  },
  select: {
    width: "100%",
    margin: "0.8rem 0 !important",
    textAlign: "left",
  },
  type_color: {
    color: "#00205b",
    fontSize: "16px",
    fontWeight: "bold",
  },
  info: {
    margin: "1rem 0",
    color: "#00205b",
  },
  MsgError: {
    marginTop: "0px",
    color: theme.palette.error.main,
    fontWeight: "bold",
  },
  select_placeholder: {
    color: "#8080806e",
  },
  labelSelect:{
    color: "#00205b !important",
    fontSize: "16px !important",
    fontWeight: "bold !important",
  },
  form_passport:{
    marginTop: '0rem !important'
  },
  buttons_content: {
    "@media screen and (max-width: 470px)": {
      flexDirection: "column",
    },
  },
  button: {
    color: "#ffffff",
    minWidth: "175px",
    height: "45px",
    fontSize: "18px",
    fontWeight: "bold",
    backgroundColor: "#00205b",
    textTransform: "none",
    "@media screen and (max-width: 490px)": {
      width: "100%",
      marginTop: "1rem",
    },
    "&:hover": {
      backgroundColor: "#00207b",
    },
  },
  btn_back: {
    textTransform: "none",
    minWidth: "175px",
    height: "45px",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#00205b",
    backgroundColor: "#ffffff",
    border: "2.5px solid #00205b",
    "@media screen and (max-width: 490px)": {
      width: "100%",
    },
  },


  alert_margin:{
    "@media screen and (max-width: 550px)": {
      maxWidth: "90%",
      margin:"auto"
    },
  },
  text_width:{

    "@media screen and (max-width: 550px)": {
      maxWidth: "90%",
    },
    "@media screen and (max-width: 480px)": {
      maxWidth: "70%",
    },
    "@media screen and (max-width: 328x)": {
      maxWidth: "40%",
    }
  },
  wrap:{
    display: 'inline-block',
    color:"#00205b",
    fontWeight: "bold",
    wordWrap: 'break-word',
    wordBreak: 'normal',
    lineBreak: 'strict',
    "@media screen and (max-width: 550px)": {
      maxWidth: '90%',
      margin: '0'
    },
  },
  link_button: {
    marginTop: "3rem",
    width: "100%",
    textTransform: "none",
    minWidth: "175px",
    height: "45px",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#00205b",
    backgroundColor: "#ffffff",
    border: "2.5px solid #00205b",

  },
  box_inicio:{
    "@media screen and (max-width: 550px)": {
      width: "90%",
      margin: 'auto'
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#FFF",
  },


}));
