import { CircularProgress, FormControl, Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";

export const FieldIdentification = (props) => {
  const {
    classes,
    value,
      inputRef,
    name,
    titleName,
    handleChange,
    placeholder,
    validation,
    mask,
    statusCode,
    error,
    errorMessage,
    disabled
  } = props;
  const { loading2 } = useSelector((state) => state.uiError);

  return (
    <FormControl className={classes.select}>
      <label>
        <Typography align="left" className={classes.type_color} variant="h6">
          {titleName}
        </Typography>
      </label>
      <TextField
        variant="outlined"
        name={name}
        value={value}
        inputRef={inputRef}
        onChange={handleChange}
        placeholder={placeholder}
        onBlur={validation}
        onKeyUp={validation}
        InputProps={{
          ...(mask && {inputComponent: mask}),
          endAdornment:(
            <InputAdornment position="end">
              {loading2 && <CircularProgress color="primary" />}
            </InputAdornment>
          ),
          className: disabled ? classes.borderStyle : ""
        }}
        inputProps={{
            ...(mask && {maxLength: 13})
        }}
        disabled={disabled}
      />
      {error && (
        <Grid>
          <Typography align="left" className={classes.MsgError}>
            {error}
          </Typography>
        </Grid>
      )}
      {statusCode === 400 && (
        <Grid>
          <Typography align="left" className={classes.MsgError}>
            {errorMessage ?? "Cédula no encontrada"}
          </Typography>
        </Grid>
      )}
      {statusCode === 429 && (
        <Grid>
          <Typography align="left" className={classes.MsgError}>
            Demasiadas peticiones en un corto periodo de tiempo. Espere 1 minuto
            y vuelva a intentarlo.
          </Typography>
        </Grid>
      )}
      {statusCode === 500 && (
        <Grid>
          <Typography align="left" className={classes.MsgError}>
            Estamos presentando problemas en nuestros servidores. Por favor
            intentarlo más tarde.
          </Typography>
        </Grid>
      )}
    </FormControl>
  );
};
