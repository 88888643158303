import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    color: "white",
    padding: "26px",
    "@media screen and (max-width: 412px)": {
      paddingLeft: "5px",
      paddingRight: "5px",
    },
  },
  link_2: {
    textDecoration: "none",
  },
  box_inicio:{
    "@media screen and (max-width: 550px)": {
      width: "90%",
      margin: 'auto'
    },
  },
  link_button: {
    marginTop: "3rem",
    width: "100%",
    textTransform: "none",
    minWidth: "175px",
    height: "45px",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#00205b",
    backgroundColor: "#ffffff",
    border: "2.5px solid #00205b",

  },
  logo: {
    width: "100%",
    padding: "3rem 0 1rem 0",
  },
  title: {
    fontWeight: "bold",
    marginBottom: "1rem",
    color: "#00205b",
  },
  sub_title: {
    fontWeight: "bold",
    color: "#00205b",
  },
  reset:{
    color: "#00205b",
    fontSize: "16px",
    textDecoration: "underline",
    cursor: "pointer",
    margin: "0.5rem 0"
  },
  select: {
    width: "100%",
    margin: "0.8rem 0",
  },
  labelSelect: {
    color: "#00205b !important",
    fontSize: "16px !important",
    fontWeight: "bold !important",
  },
  box_form: {
    border: "1px solid rgba(128, 128, 128, 0.200)",
    padding: "0 1rem",
    borderRadius: "10px",
    boxShadow: "0 0 5px rgba(0,0,0,.32)",
    marginBottom: "1.5rem",
  },
  type_color: {
    color: "#00205b",
    fontSize: "16px",
    fontWeight: "bold",
  },

  Menor: {
    marginBlockEnd:"12px",
    color: "#00205b",
    fontSize: "16px",
    fontWeight: "bold",
  },
  info: {
    margin: "1rem 0",
    color: "#00205b",
  },
  info2: {
    margin: "0.5rem 0",
    color: "#00205b",
  },
  select_placeholder: {
    color: "#8080806e",
  },
  buttons_content: {
    "@media screen and (max-width: 470px)": {
      flexDirection: "column",
    },
  },
  helperText: {
    borderRadius: "10px",
    marginTop:"3px",
    "&  .MuiFormHelperText-root.Mui-error": {
      marginTop: "5px",
      marginLeft: "2px",
      //  color: "#e57373 !important",
      fontFamily: theme.typography.fontFamily,
      fontSize: "16px",
      fontWeight: "bold",
    },
    "&  .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor:"#003876"
    },

  },
  inputText: {
    marginTop: "-1rem",
  },
  button: {
    color: "#ffffff",
    minWidth: "175px",
    height: "45px",
    fontSize: "18px",
    fontWeight: "bold",
    backgroundColor: "#00205b",
    textTransform: "none",
    "@media screen and (max-width: 490px)": {
      width: "100%",
      marginTop: "1rem",
    },
    "&:hover": {
      backgroundColor: "#00207b",
    },
  },
  btn_back: {
    textTransform: "none",
    minWidth: "175px",
    height: "45px",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#00205b",
    backgroundColor: "#ffffff",
    border: "2.5px solid #00205b",
    "@media screen and (max-width: 490px)": {
      width: "100%",
    },
  },
  MsgError: {
    marginTop: "0px",
    color: theme.palette.error.main,
    fontWeight: "bold",
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#FFF",
  },
  redLetter: {
    color: "#fe0000",
    fontWeight: "bold",
    marginBottom: "1rem",
    marginTop: "-1rem",
  },
  wrap:{
    display: 'inline-block',
    color:"#00205b",
    fontWeight: "bold",
    wordWrap: 'break-word',
    wordBreak: 'normal',
    lineBreak: 'strict',
    "@media screen and (max-width: 550px)": {
      maxWidth: '90%',
      margin: '0'
    },
  },
  border_error:{
    border: '1px solid "#fe0000"'
  },
  borderStyle: {
    border: "1px solid #80808081"
  },
  alert_margin:{
    "@media screen and (max-width: 550px)": {
      maxWidth: "90%",
      margin: "auto",
    },
  },
  text_width:{
    "@media screen and (max-width: 550px)": {
      maxWidth: "90%",
    },
    "@media screen and (max-width: 480px)": {
      maxWidth: "70%",
    },
    "@media screen and (max-width: 328x)": {
      maxWidth: "40%",
    }
  }
}));
