import { FormControl, Grid, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";

export const CenterField = (props) => {
  const {
    classes,
    titleName,
    disabled,
    centros,
    validation,
    value,
    handleChange,
    name,
    placeholder,
    error1,
    error2,
  } = props;
  
  return (
    <FormControl className={classes.select}>
      <label>
        <Typography align="left" className={classes.type_color} variant="h6">
          {titleName}
        </Typography>
      </label>
      <Autocomplete
        disabled={disabled}
        options={centros.map((centro) => centro.center_name)}
        onBlur={validation}
        inputValue={value}
        className={disabled && classes.borderStyle2}
        onChange={(e) => handleChange(name, e.target.innerText)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            value={value}
            onChange={(e) => handleChange(name, e.target.value)}
            placeholder={placeholder}
          />
        )}
      />
      {error1 && (
        <Grid>
          <Typography align="left" className={classes.MsgError}>
            {error1}
          </Typography>
        </Grid>
      )}
      {error2 && (
        <Grid>
          <Typography align="left" className={classes.MsgError}>
            {error2}
          </Typography>
        </Grid>
      )}
    </FormControl>
  );
};
