import { TYPES } from "../../types/types";
import { getDataCountry } from "../../Axios/getData";
import { setError } from "./UIError";

export const getPlacesCountry = () => {
  return (dispatch) => {
    return new Promise((res, rej) => {
      Promise.all([
        getDataCountry("/data/province/?w_page=true"),
        getDataCountry("/data/town/?w_page=true&center_is_enable=true"),
      ])
        .then((provinces) => {
          dispatch(savePlaces(provinces[0].data, provinces[1].data));
          res(true);
        })
        .catch((err) => {
          dispatch(
            setError(
              "Estamos presentando problemas en nuestro servidores, por favor intente más tarde."
            )
          );
          rej(true);
        });
    });
  };
};

export const savePlaces = (provincias, municipios) => ({
  type: TYPES.places,
  payload: {
    provincias,
    municipios,
  },
});

export const borrarPlaces = () => ({
  type: TYPES.RemovePlaces,
});
