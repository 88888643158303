import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./home.styles";
import logoThree from "../../assets/images/tres-logos-fn-azul-short2.svg";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router-dom";
import { useRef, useState } from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { getPlacesCountry } from "../../Redux/actions/actionDataCountry";
import {
  finishLoading,
  removeError,
  startLoading,
} from "../../Redux/actions/UIError";

export const Home = () => {
  const classes = useStyles();
  const history = useHistory();
  const captcha = useRef(null);
  const [Error, setError] = useState(null);
  const CaptchaKey = process.env.REACT_APP_CAPTCHA_KEY;
  const dispatch = useDispatch();
  const { loading, loading2, msgError } = useSelector((state) => state.uiError);
  const { strapiData, strapiError } = useSelector((state) => state.StrapiData)

  const eliminarErroresRedux = () => {
    setTimeout(() => {
      dispatch(removeError());
    }, 10000);
  };

  const IsValidator = () => {
    if (!captcha.current.getValue()) {
      setError("Debe validar que no es un robot");
      return false;
    } else {
      setError(null);
      return true;
    }
  };

   const handleCedula = () => {
     if (IsValidator()) {
       history.push("/persona-con-cedula");
     }
   };

  const handlePasaporte = () => {
    if (IsValidator()) {
        history.push("/persona-con-pasaporte");
    }
  };
  const handleMenor = () => {
    if (IsValidator()) {
      dispatch(startLoading());
      dispatch(getPlacesCountry())
        .then(() => {
          history.push("/menor-18");
          dispatch(finishLoading());
        })
        .catch(() => {
          dispatch(finishLoading());
        });
    }
  };

  const handleButtonsChange = (enlace) => {
    if(enlace === "/persona-con-cedula"){
      handleCedula()
    }else if(enlace === "/persona-con-pasaporte"){
      handlePasaporte()
    }else if(enlace === "/menor-18"){
      handleMenor()
    }
  }

  return (
    <Container className={classes.content}>
      <Box>
        <img src={logoThree} alt="logo" className={classes.logo} />{" "}
      </Box>
      <Typography
        align="center"
        variant="h6"
        component="h1"
        // m={}
        className={classes.title}
      >
        Solicitud de Certificado de Vacunación
      </Typography>
      <Grid item xs={12} sm={12} className={classes.captcha}>
        <ReCAPTCHA
          ref={captcha}
          sitekey={CaptchaKey}
          onChange={IsValidator}
          hl="es"
          className={classes.Recaptcha}
        />
      </Grid>
      {Error && (
        <Grid style={{ marginTop: "-10px", marginBlockEnd: "10px" }}>
          <Typography className={classes.MsgError}>{Error}</Typography>
        </Grid>
      )}
      <Alert severity="info">
        <AlertTitle>
          Por favor seleccione su grupo para completar el formulario de
          solicitud de su Certificado de Vacunación.
        </AlertTitle>
      </Alert>
      {
        loading2 ?
          <Box className={classes.box_strapi_loading} > 
            <CircularProgress size={100} color="inherit" className={classes.strapi_loading} />
          </Box>
        :
          <Box className={`${classes.box_buttons} ${strapiError ? classes.box_buttons_error : ''}`}>
            {
              strapiError ?
              <Typography className={classes.strapi_error} >
                <Alert severity="error">
                  <AlertTitle>
                    {strapiError}
                  </AlertTitle>
                </Alert>
              </Typography>
              :
              <>
                {
                  strapiData && 
                  <>
                    {
                      strapiData.Inicio.Botones.map(button => (
                        <Button
                          key={button.id}
                          disabled={!button.Habilitar} 
                          onClick={() => handleButtonsChange(button.BotonEnlace)} 
                          className={button.Habilitar ? classes.btn_old : classes.btn_old_2}
                        >
                          {button.BotonTexto}
                          {!button.Habilitar && 
                          <>
                            <br/>
                            Proximamente
                          </>}
                        </Button>
                      ))
                    }
                  </>
                }
              </>
            }
          </Box>

      }
      {msgError && (
        <Alert
          style={{ marginBottom: "2rem", marginTop: "1rem" }}
          severity="error"
        >
          <AlertTitle>{msgError}</AlertTitle>
        </Alert>
      )}
      {msgError ? eliminarErroresRedux() : ""}

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress size={100} color="inherit" />
      </Backdrop>
    </Container>
  );
};
