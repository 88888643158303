import axios from "axios";
const base_url = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_X_API_KEY;

const headers = {
  "X-API-KEY": apiKey
}

async function SenDataFormAdultosConCedula(values,DataCorreo, DataPhone) {
  const data = {
    hash: values.hash,
  };

  if (!DataCorreo) {
    data.contacto_correo= values.correo;
  }

  if (!DataPhone && values.telefono) {
    data.contacto_telefono = values.telefono;
  }
  try {
    return await axios.post(`${base_url}/adults`, data ,{
      headers: headers,
    });
  } catch (error) {
    throw error;
  }
}

async function SendDataFormAdultosSinCedula(
  values,
  PassportData

) {
  const data = {
    doc_identificacion: values.documento.toUpperCase(),
    nombres: values.nombre.toUpperCase(),
    contacto_correo: values.correo,

  };
  if (values.selectedDateFNacimiento) {
    data.fecha_nacimiento= values.selectedDateFNacimiento.toISOString();
  }
  if (values.apellido!=="") {
    data.apellidos= values.apellido.toUpperCase();
  }
  if (values.sexo!=="") {
    data.sexo= values.sexo
  }

  if ((PassportData?.phone===undefined  || PassportData?.phone===null) &&  values.telefono) {
    data.contacto_telefono = values.telefono;
  }

  try {
    return await axios.post(`${base_url}/adults-without-document`, data, {
      headers: headers
    });

  } catch (error) {
    throw error;
  }
}

async function SendDataFormMenores(formValues) {
  const { step1, step2, step3 } = formValues

  const data = {
    nombres: step1.nombre.toUpperCase(),
    apellidos: step1.apellido.toUpperCase(),
    sexo: step1.sexo,
    fecha_nacimiento: step1.DateNacimiento.toISOString(),
    contacto_nombre_tutor: step2.nombreTutor.toUpperCase(),
    tipo_documento_contacto_tutor: step2.TipoIdentTutor,
    numero_documento_contacto_tutor: step2.DocIdentifitutor.toUpperCase(),
    contacto_correo_tutor: step2.correoTutor,

    dosis: [
      {
        dosis: 1,
        id_prov: step3.provincia1,
        id_mun: step3.municipio1,
        id_centro: step3.centro1,
        tipo_documento: step3.idTypes.TipoIdent1,
        numero_documento_tutor: step3.cedula1.toUpperCase(),
        nombre_tutor: step3.nombre1.toUpperCase(),
        fecha_aplicacion: step3.dates.selectedDate1.toISOString(),
        ...(step3.tarjeta1 && {codigo_tarjeta: step3.tarjeta1.toUpperCase()}),
      },
      {
        dosis: 2,
        id_prov: step3.provincia2,
        id_mun: step3.municipio2,
        id_centro: step3.centro2,
        tipo_documento: step3.sameTutorInfo ? step3.idTypes.TipoIdent1 : step3.idTypes.TipoIdent2,
        numero_documento_tutor: step3.sameTutorInfo ? step3.cedula1.toUpperCase() : step3.cedula2.toUpperCase(),
        nombre_tutor: step3.sameTutorInfo ? step3.nombre1.toUpperCase() : step3.nombre2.toUpperCase(),
        fecha_aplicacion: step3.dates.selectedDate2.toISOString(),
        ...((step3.tarjeta1 && step3.sameTarjectCode) && {codigo_tarjeta: step3.tarjeta1.toUpperCase()}),
        ...(step3.tarjeta2 && {codigo_tarjeta: step3.tarjeta2.toUpperCase()})
      },
    ],
  };
  if (step1.documento) {
    data.doc_identificacion = step1.documento.toUpperCase();
  }

  if (step2.numeroTutor) {
    data.contacto_telefono_tutor = step2.numeroTutor;
  }

  if (step3.provincia3) {
    data.dosis[2] = {
      dosis: 3,
      id_prov: step3.provincia3,
      id_mun: step3.municipio3,
      id_centro: step3.centro3,
      tipo_documento: step3.sameTutorInfo ? step3.idTypes.TipoIdent1 : step3.idTypes.TipoIdent3,
      numero_documento_tutor: step3.sameTutorInfo ? step3.cedula1.toUpperCase() : step3.cedula3.toUpperCase(),
      nombre_tutor: step3.sameTutorInfo ? step3.nombre1.toUpperCase() : step3.nombre3.toUpperCase(),
      fecha_aplicacion: step3.dates.selectedDate3.toISOString(),
      ...((step3.tarjeta1 && step3.sameTarjectCode) && {codigo_tarjeta: step3.tarjeta1.toUpperCase()}),
      ...(step3.tarjeta3 && {codigo_tarjeta: step3.tarjeta3.toUpperCase()})
    };
  }

  if (step3.provincia4) {
    data.dosis[3] = {
      dosis: 4,
      id_prov: step3.provincia4,
      id_mun: step3.municipio4,
      id_centro: step3.centro4,
      tipo_documento: step3.sameTutorInfo ? step3.idTypes.TipoIdent1 : step3.idTypes.TipoIdent4,
      numero_documento_tutor: step3.sameTutorInfo ? step3.cedula1.toUpperCase() : step3.cedula4.toUpperCase(),
      nombre_tutor: step3.sameTutorInfo ? step3.nombre1.toUpperCase() : step3.nombre4.toUpperCase(),
      fecha_aplicacion: step3.dates.selectedDate4.toISOString(),
      ...((step3.tarjeta1 && step3.sameTarjectCode) && {codigo_tarjeta: step3.tarjeta1.toUpperCase()}),
      ...(step3.tarjeta4 && {codigo_tarjeta: step3.tarjeta4.toUpperCase()})
    };
  }

  try {
    return await axios.post(`${base_url}/solicitud-certificado-menores/forms`, data, {
      headers: { "X-API-KEY": apiKey },
    });
  } catch (error) {
    throw error;
  }
}

async function CheckPassportValid(pasapoprte) {
  try {
    return await axios.get(base_url + "/adults-without-document/verify?", {
      params: {
        doc_identificacion: pasapoprte,
      },
      headers: { "X-API-KEY": apiKey },
    });
  } catch (error) {
    throw error;
  }
}

async function CheckDocIdentValid(documento) {
  try {
    return await axios.get(
      base_url + "/solicitud-certificado-menores/valid_document?",
      {
        params: {
          doc_identificacion: documento,
        },
        headers: { "X-API-KEY": apiKey },
      }
    );
  } catch (error) {
    throw error;
  }
}

async function SendVerifyCedula(cedula) {
  try {
    return await axios.get(base_url + "/citizens/name?", {
      params: {
        cedula: cedula,
      },
      headers: { "X-API-KEY": apiKey },
    });
  } catch (error) {
    throw error;
  }
}

async function ObtenerDataCedulaFormCedula(cedula) {
  try {
    return await axios.get(base_url + "/adults?", {
      params: {
        cedula: cedula,
      },
      headers: { "X-API-KEY": apiKey, "username": "SOLICITUD" },
    });
  } catch (error) {
    throw error;
  }
}

export {
  SenDataFormAdultosConCedula,
  SendDataFormAdultosSinCedula,
  SendDataFormMenores,
  CheckPassportValid,
  CheckDocIdentValid,
  SendVerifyCedula,
  ObtenerDataCedulaFormCedula,
};
