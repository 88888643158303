import { TYPES } from "../../types/types";

export const DataFormReducer = (state = {}, action) => {
  switch (action.type) {
    case TYPES.SaveAdultSinCedula:
      return {
        statusCode: action.payload.statusCode,
        message: action.payload.message,
      };

    case TYPES.RemoveAdultSinCedula:
      return {};

    default:
      return state;
  }
};
