import {TYPES} from "../../types/types";


export  const SetActiveStep =(CountState)=>({
    type: TYPES.ActiveStep,
    payload: {
        CountState
    }
});


export  const SalveValuesChangeStep =(values)=>({
    type: TYPES.ChangeStep,
    payload: {
        values
    }
});


export const RemoveGlobalDataValue=()=>({
    type:TYPES.RemoveGlobaldataValue
})

export const RemoveActiveStep=()=>({
    type:TYPES.RemoveActiveStep
})



export  const SalveValuesChangeStep2 = (Formvalues) => ({
    type: TYPES.ChangeStep2,
    payload: {
        Formvalues
    }
});


export const RemoveGlobalDataFormValues=()=>({
    type:TYPES.RemoveGlobalFormValue
})
