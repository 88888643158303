import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
  },
  container: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    color: "#00205C"
    
  },

  containerImg: {},
  main: {
    display:'flex',
    alignItems:'center',
    margin:'auto',
    fontSize: "1.2rem",
    padding: "20px",
    textAlign:'center',
    flexDirection:'column',
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.7rem",
      flexDirection:'row',
      gap:'70px',
    },
    "& img": {
      maxWidth:'250px',
      [theme.breakpoints.up("md")]: {
        maxWidth:'500px',
      },
    },
  },
  main_state:{
    fontWeight :"bold",
    margin: "1rem 0"
  },
  contact: {
    fontSize: "1rem",
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.5rem",
    },

    "& h2": {
      fontSize: "1rem",
    },
  },
}));

export default useStyles;