import { TYPES } from "../../types/types";

export const centerReducer = (state = {}, action) => {
  switch (action.type) {
    case TYPES.center:
      return {
        centros: action.payload.centros,
      };

    default:
      return state;
  }
};
