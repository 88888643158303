import useStyles from "./maintenance.styles";
import mantenimientoSVG from '../../assets/images/mantenimientoSVG.svg'
import { Typography } from "@material-ui/core";
import Header from "../Header/Header";
import { SocialMedia } from "../SocialMedia/SocialMedia";
import { Footer } from "../Footer/Footer";

export const MaintenancePage = () => {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <Header />
          <main className={classes.main}>
            <img
              src={mantenimientoSVG}
              width="100%"
              alt="Representación de un robot defectuoso"
            />
            <div>
              <Typography className={classes.main_state} variant="h5" component="h2">
                Aplicación en mantenimiento
              </Typography>
  
              <Typography className={classes.main_state} component="p" variant="body2" >
                Este espacio se encuentra en mantenimiento. <br /> Pronto estará
                disponible nuevamente, <br /> lamentamos las molestias.
              </Typography>
              
              <section className={classes.contact}>
                <SocialMedia />
              </section>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    );
}
