import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import React, { useEffect } from "react";
import { Layout } from "../components/layout/Layout";
import { Home } from "../components/Home/Home";
 import { FormStepCedula } from "../components/FormStepperCedula/FormStepCedula";
import { FormStepper } from "../components/FormStepper/FormStepper";
import { FormMenores } from "../components/FormMenores/FormMenores";
import PrivareRoute from "./PrivareRoute";
import { useSelector, useDispatch } from "react-redux";
import useStrapi from "../hooks/useStrapi";
import { getStrapiData, getStrapiError } from "../Redux/actions/actionStrapiData";
import { Box, CircularProgress } from "@material-ui/core";
import { useStyles } from './routes.styles'
import { MaintenancePage } from "../components/MaintenancePage/MaintenancePage";
import vacunateLogo from '../assets/images/vacunaterd.svg'

function AppRoutes() {
  // const { strapiData } = useSelector((state) => state.StrapiData);
  const [strapiData, strapiError] = useStrapi("/solicitud-de-certificado-de-vacunacion-app")
  const dispatch = useDispatch()
  const classes = useStyles()

  const SelectComponent = (path) => {
    if (path === "/persona-con-cedula") {
      return <FormStepCedula />;
    } else if (path === "/persona-con-pasaporte") {
      return <FormStepper />;
    } else if (path === "/menor-18") {
      return <FormMenores />;
    }
  };

  useEffect(() => {
    if (strapiError) {
      dispatch(getStrapiError(strapiError))
    }else{
      dispatch(getStrapiData(strapiData));
    }
  }, [strapiData, strapiError])
  return (
    <BrowserRouter>
      {
        strapiData ?
          <>
            {
              strapiData?.Mantenimiento ?
                <>
                  <Redirect to="/Mantenimiento" />
                  <Switch>
                    <Route
                      path="/Mantenimiento"
                      component={MaintenancePage}
                      exact
                    />
                  </Switch>
                </>
              :
              <Layout>
                  <Redirect to="/" />
                  <Switch>
                    <Route path={"/"} component={Home} exact />
                    {strapiData && (
                      <>
                        {strapiData.Inicio.Botones.map((boton) => {
                          if (boton.Habilitar) {
                            if (boton.BotonEnlace === "/menor-18") {
                              return (
                                <PrivareRoute
                                  path={boton.BotonEnlace}
                                  component={() => SelectComponent(boton.BotonEnlace)}
                                  key = {boton.BotonEnlace}
                                  exact
                                />
                              );
                            }
                            return (
                              <Route
                                path={boton.BotonEnlace}
                                component={() => SelectComponent(boton.BotonEnlace)}
                                key = {boton.BotonEnlace}
                                exact
                              />
                            );
                          }
                          return <></>
                        })}
                      </>
                    )}
                  </Switch>
                </Layout>
            }
          </>
        :
        <>
          <Box className={classes.box_strapi_loading} > 
            <CircularProgress size={100} color="inherit" className={classes.strapi_loading} />
            <img className={classes.vacunate_logo} src={vacunateLogo} alt="VacunateRd Logo" />
          </Box>
        </>
      }
    </BrowserRouter>
  );
}

export default AppRoutes;
