import {
  Backdrop,
  Box, Button, CircularProgress
} from "@material-ui/core";
import { useState } from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import EmailInputField from "../../campos/EmailInputField";
import PhoneNumberField from "../../campos/PhoneNumberField";
import { useStyles } from "../FormStepStyles";
import {useDispatch,useSelector} from "react-redux";
import {useForm} from "../../../hooks/useForm";
import validator from "validator/es";
import {RemoveGlobalDataValue, SalveValuesChangeStep, SetActiveStep} from "../../../Redux/actions/ActionGlobalState";
import {RequestDataFormConCedula} from "../../../Redux/actions/ActionDataForm";
import {setError, startLoading} from "../../../Redux/actions/UIError";
import {DeleteRequestVerifyDocIndent} from "../../../Redux/actions/ActionCheckDocIdent";

export const Step2 = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { StatusDocIdent, PassportData } = useSelector((state) => state.VerifyDocident);
  const { CountState, GlobalvaluesData } = useSelector((state) => state.GlobalStateAPP);
  const {msgError, loading } = useSelector((state) => state.uiError);
  const [values, handleInputChange, setValues] = useForm({
    telefono: [GlobalvaluesData?.telefono ?? "", ""],
    correo: [GlobalvaluesData?.correo ?? "", ""],
  });
  const { telefono, correo } = values;
  const RegetEmail = new RegExp(/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/);
  const RegetPhoneNumber = new RegExp(/^[\d*]{10,15}$/);
  const [ErrorPhoneNumber, setErrorPhoneNumber] = useState(null);
  const [ErrorEmail, setErrorEmail] = useState(null);


  const handleNext = () => {

      if (IsValidatePhoneNumber() & isValidatorEmail()) {
        const UpdateValue = {
          ...GlobalvaluesData,
          telefono: telefono,
          correo: correo,
        };
        console.log(UpdateValue);
        dispatch(RequestDataFormConCedula(UpdateValue, GlobalvaluesData?.correo, GlobalvaluesData?.telefono))
            .then((result) => {
              dispatch(startLoading());
              window.open(result.twoFaceLink, "_self");
              dispatch(DeleteRequestVerifyDocIndent());
              dispatch(RemoveGlobalDataValue());
            })
            .catch((error) => {
              dispatch(setError(error));
            });
      }

  };

  const handleBack = () => {
    const UpdateValue = {
      ...GlobalvaluesData,
      telefono: telefono,
      correo: correo,
    };
   dispatch(SalveValuesChangeStep(UpdateValue));
    dispatch(SetActiveStep(CountState - 1));
  };




  //-------Validaciones Step2

  const IsValidatePhoneNumber = () => {
    if (!validator.isEmpty(telefono)) {
      if (!RegetPhoneNumber.test(telefono)) {
        setErrorPhoneNumber("Formato incorrecto");
        return false;
      } else {
        setErrorPhoneNumber(null);
        return true;
      }
    } else {
      setErrorPhoneNumber(null);
      return true;
    }
  };

  const isValidatorEmail = () => {
    if (validator.isEmpty(correo)) {
      setErrorEmail("Campo requerido");
      return false;
    }

    if (!RegetEmail.test(correo)) {
      setErrorEmail("Formato incorrecto");
      return false;
    } else {
      setErrorEmail(null);
      return true;
    }
  };




  // Componentes Visuales del Step 2
  const emailFieldProps = {
    classes,
    value: values.correo,
    handleChange: handleInputChange,
    validation: isValidatorEmail,
    error: ErrorEmail,
    placeholder: 'ejemplo@gmail.com',
    name: 'correo',
    titleName: 'Correo electrónico *',
    disabled: !!PassportData?.email,
  };

  const phoneNumberFieldProps = {
    classes,
    value: values.telefono,
    handleChange: handleInputChange,
    validation: IsValidatePhoneNumber,
    error: ErrorPhoneNumber,
    placeholder: "Ej.: 8295555555",
    name: "telefono",
    titleName: "Número de teléfono",
    disabled: !!PassportData?.phone
  };

  return (
    <Box>
      <Alert severity="info">
        <AlertTitle>
          Por esta vía se le enviará instrucciones para obtener su  Certificado de Vacunación.
        </AlertTitle>
      </Alert>

      <EmailInputField {...emailFieldProps} />
      <PhoneNumberField  {...phoneNumberFieldProps}/>
      {
        msgError &&
        <Alert severity="error">
          <AlertTitle>{msgError}</AlertTitle>
        </Alert>
      }      <Box
          my={2}
          display="flex"
          justifyContent="space-around"
          className={classes.buttons_content}
      >
        <Button
            className={classes.btn_back}
            variant="contained"
            onClick={handleBack}
        >
          Ir atrás
        </Button>
        <Button
            className={classes.button}
            variant="contained"
            onClick={handleNext}
        >
          Enviar
        </Button>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress size={100} color="inherit" />
        </Backdrop>
      </Box>




    </Box>
  );
};
