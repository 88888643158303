import { useState } from "react";

export const useShowHide = (initialState = {}) => {
  const [Values, setValues] = useState(initialState);

  const reset = () => {
    setValues(initialState);
  };

  const handleInputChange = (event) => {
    let name = event.currentTarget.dataset.name;

    setValues((values) => ({
      ...values,
      [name]: !values[name],
    }));
  };

  return [Values, handleInputChange, reset];
};
