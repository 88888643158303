import axios from "axios";
const dataApi = process.env.REACT_APP_API_CENTROSVAC_URL;

export const getDataCountry = async (url) => {
  const data = await axios.get(`${dataApi}${url}`);
  return data;
};

export const getCenter = async (url) => {
  const data = await axios.get(`${dataApi}${url}`);
  return data;
};
