import { TYPES } from "../../types/types";


export const getStrapiData = (strapiData) => {
  return (dispatch) => {
    dispatch(saveStrapiData(strapiData))
  }
}


const saveStrapiData = (strapiData) => ({
  type: TYPES.strapiData,
  payload: {
    strapiData,
  },
});

export const getStrapiError = (strapiError) => {
  return (dispatch) => {
    dispatch(saveStrapiError(strapiError))
  }
}  

const saveStrapiError = (strapiError) => ({
  type: TYPES.strapiError,
  payload: {strapiError}
});