import {TYPES} from "../../types/types";


const initialState ={
    CountState: 0,
    GlobalvaluesData: null,
    GlobalFormValues:null
}

export const GlobalStateReducer =(state={...initialState}, action)=>{
    switch (action.type) {
        case TYPES.ActiveStep:
            return {
                ...state,
                CountState: action.payload.CountState
            }
        case TYPES.ChangeStep:
            return {
                ...state,
                GlobalvaluesData: action.payload.values
            }
        case TYPES.RemoveGlobaldataValue:
            return {
                ...state,
                GlobalvaluesData: null
            }

        case TYPES.ChangeStep2:
            return {
                ...state,
                GlobalFormValues: action.payload.Formvalues
            }

        case TYPES.RemoveGlobalFormValue:{
            return {
                ...state,
                GlobalFormValues: null
            }
        }

        case TYPES.RemoveActiveStep:{
            return {
                ...state,
                CountState: 0
            }
        }

        default:
            return state;

    }


}
