import {
  Box,
  Container,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import logoThree from "../../assets/images/tres-logos-fn-azul-short2.svg";
import { useStyles } from "./FormMenores.styles";
import { Step1, Step2, Step3, Step4 } from "./steps";
import { useSelector } from "react-redux";

function getSteps() {
  return [
    "Información básica del Menor",
    "Información del Tutor",
    "Detalles de vacunas",
  ];
}

function getStepCopntent(step) {
  switch (step) {
    case 0:
      return (
        <Step1/>
      );
    case 1:
      return (
        <Step2/>
      );
    case 2:
      return (
        <Step3/>
      );
    case 3: 
        return (
          <Step4 />
        )
    default:
      return "unknown step";
  }
}

export const FormMenores = () => {
  const classes = useStyles();
  const { CountState } = useSelector((state) => state.GlobalStateAPP);
  const steps = getSteps();

  return (
    <Container className={classes.content}>
      <Box>
        <img src={logoThree} alt="logo" className={classes.logo} />{" "}
      </Box>
      <Typography
        align="center"
        variant="h6"
        component="h1"
        m={1}
        className={classes.title}
      >
        Solicitud de Certificado de Vacunación
      </Typography>
      <Typography
        align="center"
        variant="h6"
        component="h1"
        className={classes.redLetter}
      >
        para Menores de 18 años
      </Typography>
      <Box>
        <Stepper alternativeLabel activeStep={CountState}>
          {steps.map((step, index) => {
            const labelProps = {};
            const stepProps = {};

            return (
              <Step {...stepProps} key={index}>
                <StepLabel {...labelProps}>{step}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

          <Box className={classes.alert_margin}>

          </Box>
          <>
            <form>{getStepCopntent(CountState)}</form>
          </>
      </Box>
    </Container>
  );
};
