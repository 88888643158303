import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import React from "react";

export const ProvinceField = (props) => {
  const {
    classes,
    name,
    titleName,
    value,
    error,
    handleChange,
    disabled,
    provincias,
    validation,
  } = props;

  return (
    <FormControl variant="outlined" className={classes.select}>
      <label>
        <Typography align="left" className={classes.type_color} variant="h6">
          {titleName}
        </Typography>
      </label>
      <Select
        name={name}
        value={value}
        onChange={handleChange}
        displayEmpty
        onBlur={validation}
        className={disabled ? classes.borderStyle : ""}
        disabled={disabled}
      >
        {!value && (
          <MenuItem value="" disabled>
            <div className={classes.select_placeholder}>
              Seleccione una provincia
            </div>
          </MenuItem>
        )}
        {provincias &&
          provincias.map((provincia) => (
            <MenuItem key={provincia.provinciaId} value={provincia.provinciaId}>
              {provincia.provincia}
            </MenuItem>
          ))}
      </Select>
      {error && (
        <Grid>
          <Typography align="left" className={classes.MsgError}>
            {error}
          </Typography>
        </Grid>
      )}
    </FormControl>
  );
};
