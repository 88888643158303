import { combineReducers, createStore, compose, applyMiddleware } from "redux";
import { dataReducer } from "../reducers/dataReducer";
import thunk from "redux-thunk";
import { UIErrorReducer } from "../reducers/UIErrorReducer";
import { centerReducer } from "../reducers/centerReducer";
import { DataFormReducer } from "../reducers/DataFormReducer";
import {CheckDocIdentReducer} from "../reducers/CheckDocIdentReducer";
import {GlobalStateReducer} from "../reducers/GlobalStateReducer";
import { strapiReducer } from "../reducers/strapiReducer";
//Extension para redux-dev-tool
const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const reducer = combineReducers({
  CountryData: dataReducer,
  CenterData: centerReducer,
  FormData: DataFormReducer,
  StrapiData: strapiReducer,
  VerifyDocident:CheckDocIdentReducer,
  GlobalStateAPP: GlobalStateReducer,
  uiError: UIErrorReducer,
});

export const store = createStore(
  reducer,
  composeEnhancers(
    //Milldeware
    applyMiddleware(thunk)
  )
);
