import { CheckDocIdentValid, CheckPassportValid } from "../../Axios/sendForm";
import { error500, finishLoading2, startLoading2 } from "./UIError";
import { TYPES } from "../../types/types";

export const SendDataVerifyPassport = (passport) => {
  return (dispatch) => {
    return new Promise((res, req) => {
      dispatch(startLoading2());
      CheckPassportValid(passport)
        .then((data) => {
          dispatch(finishLoading2());
          const statusCode = data.status;
          const PassportData = data.data;
          dispatch(SalveRequestVerifyDocIdent(statusCode, PassportData, ""));
          res(PassportData);
        })
        .catch((error) => {
          console.log(error.request.response);
          dispatch(finishLoading2());
          const PassportData = null;
          const { statusCode } = JSON.parse(error.request.response);
          if(statusCode === 500){
            dispatch(error500(""))
          }
          dispatch(SalveRequestVerifyDocIdent(statusCode, PassportData));
        });
    });
  };
};

export const SendDataVerifyDocIdent = (documento) => {
  return (dispatch) => {
    dispatch(startLoading2());
    CheckDocIdentValid(documento)
      .then((data) => {
        dispatch(finishLoading2());
        const statusCode = data.status;
        dispatch(SalveRequestVerifyDocIdent(statusCode));
      })
      .catch((error) => {
        dispatch(finishLoading2());
        const { message, statusCode } = JSON.parse(error.request.response);
        const PassportData = null;
        if(statusCode === 500) {
            dispatch(error500(""))
        }
        dispatch(SalveRequestVerifyDocIdent(statusCode, PassportData, message));
      });
  };
};

export const SalveRequestVerifyDocIdent = (
  StatusDocIdent,
  PassportData,
  MessageDocIdent
) => ({
  type: TYPES.SaveRequestVerifyDoc,
  payload: {
    StatusDocIdent,
    PassportData,
    MessageDocIdent,
  },
});

export const SalveCedulaTutor = (status, cedula) => ({
  type: TYPES.SavecedulaTutor,
  payload: {
    status,
    cedula,
  },
});

export const SalveCedula1 = (status, cedula) => ({
  type: TYPES.SaveCedula1,
  payload: {
    status,
    cedula,
  },
});

export const SalveCedula2 = (status, cedula) => ({
  type: TYPES.SaveCedula2,
  payload: {
    status,
    cedula,
  },
});

export const SalveCedula3 = (status, cedula) => ({
  type: TYPES.SaveCedula3,
  payload: {
    status,
    cedula,
  },
});

export const SalveCedula4 = (status, cedula) => ({
  type: TYPES.SaveCedula4,
  payload: {
    status,
    cedula,
  },
});

export const DeleteCedula1 = () => ({
  type: TYPES.RemoveSaveCedula1,
});

export const DeleteCedula2 = () => ({
  type: TYPES.RemoveSaveCedula2,
});

export const DeleteCedula3 = () => ({
  type: TYPES.RemoveSaveCedula3,
});

export const DeleteCedula4 = () => ({
  type: TYPES.RemoveSaveCedula4,
});

export const DeleteRequestVerifyDocIndent = () => ({
  type: TYPES.RemoveRequestVerifyDoc,
});
