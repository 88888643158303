import { useEffect, useRef } from "react";
import { getCenter } from "../Axios/getData";

// Hace la peticion de los centros
export const useGetCenter = ([municipio, centros, centro, centerError]) => {
  const timer = useRef()
  useEffect(() => {
    clearInterval(timer.current)
    timer.current = setInterval(() => {
      if (municipio) {
        getCenter(
          `/data/center/?limit=25&municipality=${municipio}&name__icontains=${centro}&ad=true`
        )
          .then((response) => {
            centros(response.data.results);
            centerError("");
          })
          .catch((err) => {
            centerError("Error en servidores, intentelo más tarde");
          });
      }
      clearInterval(timer.current)
    }, 350)
  }, [municipio, centro]);
};
