import {
    FormControl,
    Grid,
    Typography,
  } from "@material-ui/core";

import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
  } from "@material-ui/pickers";

import es from "date-fns/locale/es";
import DateFnsUtils from "@date-io/date-fns";

const DateField = (props) => {

    const {
        classes,
        value,
        handleChange,
        validation,
        error,
        placeholder,
        titleName,
        disabled,
        identidicationData,
        invalidDateMessage,
        maxDateMessage,
        minDateMessage,
        KeyboardButtonProps,
        InputLabelProps,
        InputProps,
        titleClass,
        name
      } = props;

    return (
        <FormControl className={classes.select}>
        <label>
          <Typography
            align="left"
            className={titleClass}
            variant="h6"
          >
            {titleName}
          </Typography>
        </label>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <KeyboardDatePicker
            disabled={disabled}
            className={classes.helperText}
            inputVariant="outlined"
            required
            placeholder={placeholder}
            invalidDateMessage={invalidDateMessage}
            maxDateMessage={maxDateMessage}
            minDateMessage={minDateMessage}
            disableFuture={true}
            format="dd/MM/yyyy"
            margin="normal"
            id='date-picker-inline'
            value={value}
            onChange={(e) => {
              handleChange(e, name)
            }}
            onBlur={validation}
            onKeyUp={validation}
            KeyboardButtonProps={KeyboardButtonProps}
            InputLabelProps={InputLabelProps}
            InputProps={InputProps}
          />
        </MuiPickersUtilsProvider>
        {(identidicationData === null || identidicationData === undefined) &&
          error && (
            <Grid>
              <Typography align="left" className={classes.MsgError}>
                {error}
              </Typography>
            </Grid>
          )}
      </FormControl>
    )
}

export default DateField;