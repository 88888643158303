import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { useStyles } from "../FormStepperStyles";
import validator from "validator/es";
import {  useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteRequestVerifyDocIndent,
  SendDataVerifyPassport,
} from "../../../Redux/actions/ActionCheckDocIdent";
import { FieldName } from "../../campos/FieldName";
import { FieldIdentification } from "../../campos/FieldIdentification";
import DateField from "../../campos/DateField";
import {
  RemoveGlobalDataValue,
  SalveValuesChangeStep,
  SetActiveStep,
} from "../../../Redux/actions/ActionGlobalState";
import { useHistory } from "react-router";
import { useValidatorNames } from "../../../hooks/useValidatorNames";
import { useForm } from "../../../hooks/useForm";
import { useValidator } from "../../../hooks/useValidator";

export const Step1 = () => {
  const classes = useStyles();
  const namesRegx = new RegExp(
    /^[a-zA-Z.-\u00f1\u00d1]+( +[a-zA-Z0.-\u00f1\u00d1]+)*$/
  );
  const FregularPasp = new RegExp(/^([A-Z0-9]){3,20}$/);
  const dispatch = useDispatch();
  const { CountState, GlobalvaluesData } = useSelector((state) => state.GlobalStateAPP
  );
  const history = useHistory();
  const [values, handleInputChange, setValues, valuesReset] = useForm({
    documento: [GlobalvaluesData?.documento ?? "", ""],
    nombre: [GlobalvaluesData?.nombre ?? "", ""],
    apellido: [GlobalvaluesData?.apellido ?? "", ""],
    sexo: [GlobalvaluesData?.sexo ?? "", ""],
    telefono: [GlobalvaluesData?.telefono ?? "", ""],
    correo: [GlobalvaluesData?.correo ?? "", ""],
  });
  const { documento, nombre, apellido, sexo } = values;
  const [selectedDateFNacimiento, setSelectedDateFNacimiento] = useState(
    GlobalvaluesData ? GlobalvaluesData.selectedDateFNacimiento : null
  );
  const handleDateChangeFNacimiento = (date) => {
    setSelectedDateFNacimiento(date);
  };
  const [ErrorAdultoNacimiento, setErrorAdultoNacimiento] = useState(null);
  const [ErrorPasaporte, setErrorPasaporte] = useState(null);
  const { StatusDocIdent, PassportData } = useSelector((state) => state.VerifyDocident
  );

  //Validaciones Step 1
  const HandleResetDataForm = () => {
    valuesReset();
    handleDateChangeFNacimiento(null);
    dispatch(DeleteRequestVerifyDocIndent());
    dispatch(RemoveGlobalDataValue());
  };

  const IsValidatorPassport = (bandera) => {
    const pasaporte = documento.toUpperCase();
    if (validator.isEmpty(pasaporte)) {
      if (StatusDocIdent === 200) {
        HandleResetDataForm();
      }
      dispatch(DeleteRequestVerifyDocIndent());
      dispatch(RemoveGlobalDataValue());
      setErrorPasaporte("Campo requerido");
      return false;
    }

    if (!FregularPasp.test(pasaporte)) {
      if (StatusDocIdent === 200) {
        dispatch(DeleteRequestVerifyDocIndent());
        dispatch(RemoveGlobalDataValue());
        HandleResetDataForm();
      }
      dispatch(DeleteRequestVerifyDocIndent());
      dispatch(RemoveGlobalDataValue());
      setErrorPasaporte("Formato incorrecto");
      return false;
    } else {
      setErrorPasaporte(null);
      if (bandera.type === "blur") {
        dispatch(SendDataVerifyPassport(pasaporte)).then((PassportData) => {
          const { firstName, lastName, sex, dateOfBirth, email, phone } =
            PassportData;
          if (PassportData) {
            const changeName = {
              ...values,
              nombre: firstName ? firstName : "",
              apellido: lastName ? lastName : "",
              sexo: sex ? sex : "",
              telefono: phone ? phone : "",
              correo: email ? email : "",
            };
            const fecha = new Date(dateOfBirth);
            setValues(changeName);
            dateOfBirth
              ? setSelectedDateFNacimiento(fecha)
              : setSelectedDateFNacimiento(null);
          }
        });
      }
    }
  };

  const IsValidatorPassportClick = () => {
    const pasaporte = documento.toUpperCase();
    if (validator.isEmpty(pasaporte)) {
      if (StatusDocIdent) {
        dispatch(DeleteRequestVerifyDocIndent());
      }
      setErrorPasaporte("Campo requerido");
      return false;
    }
    if (!FregularPasp.test(pasaporte)) {
      if (StatusDocIdent) {
        dispatch(DeleteRequestVerifyDocIndent());
      }
      setErrorPasaporte("Formato incorrecto");
      return false;
    } else {
      setErrorPasaporte(null);
      return true;
    }
  };

  const [ErrorNombre, IsValidateFirst_Name] = useValidatorNames(
    nombre,
    "Campo requerido",
    { regx: namesRegx, msg: "Este campo incluye caracteres no permitidos" }
  );
  const [ErrorApellidos, IsValidateLastName] = useValidatorNames(
    apellido,
    "Campo requerido",
    { regx: namesRegx, msg: "Este campo incluye caracteres no permitidos" }
  );
  const [ErrorSexo, ValidateIsSexo] = useValidator(
    sexo,
    "Debe seleccionar una opción"
  );

  const ValidateAdultoFNacimiento = () => {
    if (selectedDateFNacimiento === null) {
      setErrorAdultoNacimiento("Campo requerido");
      return false;
    }
    if (!PassportData?.dateOfBirth) {
      if (selectedDateFNacimiento !== null) {
        setErrorAdultoNacimiento(null);
        const year =
          new Date().getFullYear() - selectedDateFNacimiento.getFullYear();
        const [day, month] = new Date().toLocaleDateString().split("/");
        const [dayNacimiento, monthNacimeinto] = selectedDateFNacimiento
          .toLocaleDateString()
          .split("/");
        if (year < 17) {
          setErrorAdultoNacimiento(
            "Para completar este formulario debe tener 18 o más años"
          );
          return false;
        }
        if (year === 17 && day <= dayNacimiento && month <= monthNacimeinto) {
          setErrorAdultoNacimiento(
            "Para completar este formulario debe tener 18 o más años"
          );
          return false;
        } else {
          setErrorAdultoNacimiento(null);
          return true;
        }
      } else {
        setErrorAdultoNacimiento(null);
        return true;
      }
    } else {
      setErrorAdultoNacimiento(null);
      return true;
    }
  };

  const IsAdultFechaNacimiento = () => {
    if (!PassportData?.dateOfBirth || GlobalvaluesData) {
      if (
        selectedDateFNacimiento !== null &&
        validator.isDate(selectedDateFNacimiento)
      ) {
        const date = new Date();
        const NacimitnyoCon = new Date(
          selectedDateFNacimiento.toISOString().substring(0, 10)
        );

        if (NacimitnyoCon.getTime() > date.getTime()) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  };
  //Definición de los Campos de Entrada de datos
  const fieldNameProps = {
    classes,
    value: values.nombre.toUpperCase(),
    titleName: "Nombre(s)",
    name: "nombre",
    disabled: PassportData?.firstName  ? true : false,
    placeholder: "Nombre",
    handleChange: handleInputChange,
    validation: IsValidateFirst_Name,
    identidicationData: PassportData,
    error: ErrorNombre,
  };

  const fieldLastnameProps = {
    classes,
    value: values.apellido.toUpperCase(),
    titleName: "Apellido(s) *",
    name: "apellido",
    disabled: PassportData?.lastName,
    placeholder: "Apellido",
    handleChange: handleInputChange,
    validation: IsValidateLastName,
    identidicationData: PassportData,
    error: ErrorApellidos,
  };

  const fieldIdProps = {
    classes,
    value: values.documento.toUpperCase(),
    name: "documento",
    titleName: "Número de pasaporte *",
    handleChange: handleInputChange,
    placeholder: "XXXXXXXX",
    validation: IsValidatorPassport,
    statusCode: StatusDocIdent,
    error: ErrorPasaporte,
    errorMessage: true,
  };

  const dateFieldProps = {
    classes,
    value: selectedDateFNacimiento,
    handleChange: handleDateChangeFNacimiento,
    validation: ValidateAdultoFNacimiento,
    error: ErrorAdultoNacimiento,
    placeholder: "dd/mm/aaaa",
    titleName: "Fecha de nacimiento *",
    disabled: !!PassportData?.dateOfBirth,
    identidicationData: PassportData,
    invalidDateMessage: "Formato incorrecto",
    maxDateMessage:
      "La fecha de nacimiento no puede ser mayor a la fecha actual",
    minDateMessage: "Revise la fecha de nacimiento",
    disableFuture: true,
    KeyboardButtonProps: {
      "aria-label": "change date",
    },
    InputLabelProps: {
      shrink: true,
    },
    titleClass: classes.FechaNacimiento,
    id: "date-picker-inline",
  };

  const handleNext = () => {
    if (IsValidatorPassportClick()) {
      if (
        (StatusDocIdent === 400) &
        IsValidateFirst_Name() &
        IsValidateLastName() &
        ValidateIsSexo() &
        ValidateAdultoFNacimiento() &
        IsAdultFechaNacimiento()
      ) {
        values.selectedDateFNacimiento = selectedDateFNacimiento;
        dispatch(SetActiveStep(CountState + 1));
        dispatch(SalveValuesChangeStep(values));
      }
      if (StatusDocIdent === 200) {
        values.selectedDateFNacimiento = selectedDateFNacimiento;
        dispatch(SetActiveStep(CountState + 1));
        dispatch(SalveValuesChangeStep(values));
      }
    }

    /* setSkippedSteps(
          skippedSteps.filter((skipItem) => skipItem !== CountState)
      );*/
  };

  const handleBack = () => {
    history.push("/");
    dispatch(RemoveGlobalDataValue());
  };

  return (
    <Box>
      <Grid container justifyContent="flex-end">
        <Typography
          onClick={HandleResetDataForm}
          align="right"
          className={classes.reset}
        >
          Limpiar campos
        </Typography>
      </Grid>

      <FieldIdentification {...fieldIdProps} />

      <FieldName {...fieldNameProps} />
      {StatusDocIdent === 400 && (
        <Box>
          <FieldName {...fieldLastnameProps} />

          <FormControl variant="outlined" className={classes.select}>
            <label>
              <Typography
                align="left"
                className={classes.type_color}
                variant="h6"
              >
                Sexo *
              </Typography>
            </label>
            <Select
              disabled={!!PassportData?.sex}
              variant="outlined"
              name="sexo"
              value={values.sexo}
              onChange={handleInputChange}
              onBlur={ValidateIsSexo}
              displayEmpty
              inputProps={
                PassportData?.sex && {
                  className: classes.SelecStyle,
                }
              }
            >
              {!values.sexo && (
                <MenuItem value="" disabled>
                  <div className={classes.select_placeholder}>
                    Seleccione su sexo
                  </div>
                </MenuItem>
              )}
              <MenuItem value="F">Femenino</MenuItem>
              <MenuItem value="M">Masculino</MenuItem>
            </Select>
            {(PassportData === null || PassportData === undefined) &&
              ErrorSexo && (
                <Grid>
                  <Typography align="left" className={classes.MsgError}>
                    {ErrorSexo}
                  </Typography>
                </Grid>
              )}
          </FormControl>

          <DateField {...dateFieldProps} />
        </Box>
      )}
      <Box
        my={2}
        display="flex"
        justifyContent="space-around"
        className={classes.buttons_content}
      >
        <Button
          className={classes.btn_back}
          variant="contained"
          onClick={handleBack}
        >
          Ir atrás
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          onClick={handleNext}
        >
          Continuar
        </Button>
      </Box>
    </Box>
  );
};
