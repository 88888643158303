import { Typography } from "@material-ui/core";
import logoClaroVacúnate from "../../assets/images/logo-claro-vacunate.png"
import useStyles from "./header.styles";

export default function Header() {
  const classes = useStyles();
  return (
    <>
      <header className={classes.header}>
        <div className={classes.containerImg}>
          <img src={logoClaroVacúnate} alt="VacúnateRD" width="100px" />
        </div>
        <Typography component="h1" variant="h4">
          VacúnateRD
        </Typography>
      </header>
    </>
  );
}
