import MaskedInput from "react-text-mask";

export function TarjectMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\w/, "-", /\w/, /\w/, /\w/, "-", /\w/, /\w/, /\w/, "-", /\w/, /\w/, /\w/]}
      showMask
      guide={false}
    />
  );
}