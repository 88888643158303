import { useState } from 'react'

export const useValidator = (value, message, extraValues = {}) => {
    const [msgError, setMsgError] = useState(null);

    const basicValidation = () => {
        if (!value) {
            setMsgError(message);
            return false;
        }
        if(extraValues.regx){
            if(!extraValues.regx.test(value)){
                setMsgError(extraValues.msg);
                return false;
            }
        }
        setMsgError(null);
        return true;
    }

    const verify = () => {
        if (Object.keys(extraValues).includes("dependence")){
            if(extraValues.dependence){
                return basicValidation()
            }
            return true
        }else{
            return basicValidation()
        }
    }

    return [msgError, verify]
}

export const useValidateTarjeta = (value, message, regex) => {
    const [msgError, setMsgError] = useState("")
    const verify = () => {
        if(!value){
            setMsgError("")
            return true
        }
        if(!regex.test(value)){
            setMsgError(message)
            return false
        }else{
            setMsgError("")
            return true
        }
    }
    return [msgError, verify]
}