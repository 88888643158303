import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    color: "white",
    padding: "26px",
    "@media screen and (max-width: 412px)": {
      paddingLeft: "5px",
      paddingRight: "5px",
    },
  },
  logo: {
    width: "100%",
    padding: "3rem 0 1rem 0",
  },
  title: {
    fontWeight: "bold",
    marginBottom: "1rem",
    color: "#00205b",
  },
  info: {
    margin: "0rem 0",
    color: "#00205b",
  },
  box_buttons: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  btn_old: {
    backgroundColor: "#fe0000",
    padding: "1rem",
    color: "white",
    margin: "1rem 0",
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
    fontWeight: "bold",
    width: "100%",
    textTransform: "none",
    fontSize: "22px",
    height: 'calc(60px + 2rem)'
  },
  btn_old_2: {
    backgroundColor: "gray",
    padding: "1rem",
    color: "white",
    margin: "1rem 0",
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
    fontWeight: "bold",
    width: "100%",
    textTransform: "none",
    fontSize: "18px",
    height: 'calc(60px + 2rem)'
  },
  Link: {
    textDecoration: "none",
  },
  captcha: {
    marginTop: "10px",
    width: theme.transitions,
    flexGrow: 0,
    flexBasis: 100,
    justifyContent: "center",
  },
  Recaptcha: {
    "@media screen and (max-width: 425px)": {
      transform: "scale(0.9)",
      transformOrigin: "0 0",
      transformBox: "fill-box",
      "&::-webkit-transform-origin": "0 0",
    },
    "@media screen and (max-width: 395px)": {
      transform: "scale(0.8)",
      transformOrigin: "0 0",
      transformBox: "fill-box",
      "&::-webkit-transform-origin": "0 0",
    },
    "@media screen and (max-width: 365px)": {
      transform: "scale(0.7)",
      transformOrigin: "0 0",
      transformBox: "fill-box",
      "&::-webkit-transform-origin": "0 0",
    },
    "@media screen and (max-width: 325px)": {
      transform: "scale(0.65)",
      transformOrigin: "0 0",
      "&::-webkit-transform-origin": "0 0",
    },
    "@media screen and (max-width: 315px)": {
      transform: "scale(0.6)",
      transformOrigin: "0 0",
      "&::-webkit-transform-origin": "0 0",
    },

    "@media screen and (max-width: 250px)": {
      transform: "scale(0.5)",
      transformOrigin: "0 0",
      "&::-webkit-transform-origin": "0 0",
    },
  },
  MsgError: {
    marginTop: "-10px",
    color: theme.palette.error.main,
    fontWeight: "bold",
    "@media screen and (max-width: 425px)": {
      marginTop: "-20px",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#FFF",
  },
  redLetter: {
    color: "#fe0000",
    fontWeight: "bold",
    marginBottom: "1rem",
    marginTop: "-1rem",
  },
  strapi_error:{
    color: "#00205b",
    padding: "20%",
    textAlign: "center"
  },box_buttons_error:{
    backgroundColor: "#FDECEA",
    marginTop: "1rem"

  },
  strapi_loading:{
    color: "#00205b",
    padding: "20% 0"
  },
  box_strapi_loading:{
    display: "flex",
    justifyContent :"center"
  }
}));
