import { TYPES } from "../../types/types";

export const dataReducer = (state = {}, action) => {
  switch (action.type) {
    case TYPES.places:
      return {
        provincias: action.payload.provincias,
        municipios: action.payload.municipios,
      };

    case TYPES.RemovePlaces:
      return {};

    default:
      return state;
  }
};
