import { FormControl, Grid, MenuItem, Select, Typography } from "@material-ui/core";
import React from "react";

export const TownField = (props) => {
    const { 
        classes,
        value,
        name,
        municipios,
        province,
        titleName,
        handleChange,
        validation,
        disabled,
        error
    } = props
  return (
    <FormControl variant="outlined" className={classes.select}>
      <label>
        <Typography align="left" className={classes.type_color} variant="h6">
          {titleName}
        </Typography>
      </label>
      <Select
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={validation}
        displayEmpty
        className={!province ? classes.borderStyle : ""}
        disabled={disabled}
      >
        {!value && (
          <MenuItem value="" disabled>
            <div className={classes.select_placeholder}>
              Seleccione un municipio
            </div>
          </MenuItem>
        )}
        {municipios &&
          municipios.map((municipio) => {
            if (municipio.provinciaId === province) {
              return (
                <MenuItem
                  key={municipio.municipioId}
                  value={municipio.municipioId}
                >
                  {municipio.municipio}
                </MenuItem>
              );
            }
          })}
      </Select>
      {error && (
        <Grid>
          <Typography align="left" className={classes.MsgError}>
            {error}
          </Typography>
        </Grid>
      )}
    </FormControl>
  );
};
