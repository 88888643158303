import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  header: {
    background: "#00205C",
    color: "#fff",
    display: "flex",
    flexDirection: "row",
    padding: "0px 20px",
    fontSize: "1rem",
    "& h1":{
      display:'flex',
      alignItems:'center',
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.7rem",
    },
  },
  containerImg: {},
}));

export default useStyles;