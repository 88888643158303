import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import React from "react";

export const TipeDocument = (props) => {
  const {
    classes,
    value,
    handleChange,
    handleReset,
    validation,
    error,
    nameFields,
    name,
    titleName,
    valueControlCed,
    labelControlCed,
    valueControlPas,
    labelControlPas,
  } = props;
  return (
    <>
      <FormControl className={classes.select}>
        <FormLabel className={classes.labelSelect}>{titleName}</FormLabel>
        <RadioGroup
          onChange={(e) => {
            handleChange(e);
            handleReset(nameFields);
          }}
          name={name}
          value={value}
          onBlur={validation}
        >
          <FormControlLabel
            className={classes.type_color}
            value={valueControlCed}
            control={<Radio />}
            label={labelControlCed}
          />
          <FormControlLabel
            className={classes.type_color}
            value={valueControlPas}
            control={<Radio />}
            label={labelControlPas}
          />
        </RadioGroup>
        {error && (
          <Grid>
            <Typography align="left" className={classes.MsgError}>
              {error}
            </Typography>
          </Grid>
        )}
      </FormControl>
    </>
  );
};
