import {Box, Button} from "@material-ui/core";
import { useStyles } from "../FormStepStyles";
import { TextMaskCustom } from "../../../util/testMask";
import {useDispatch, useSelector} from "react-redux";
import { FieldName } from "../../campos/FieldName";
import { FieldIdentification } from "../../campos/FieldIdentification";
import validator from "validator/es";
import {  error500,
  finishLoading2,
  removeError, setError,
  startLoading,
  startLoading2} from "../../../Redux/actions/UIError";
import {ObtenerDataCedulaFormCedula} from "../../../Axios/sendForm";
import {DeleteRequestVerifyDocIndent,SalveRequestVerifyDocIdent} from "../../../Redux/actions/ActionCheckDocIdent";
import {RemoveGlobalDataValue, SalveValuesChangeStep, SetActiveStep} from "../../../Redux/actions/ActionGlobalState";
import {useHistory} from "react-router-dom";
import {useForm} from "../../../hooks/useForm";
import {useState} from "react";

export const Step1 = () => {
  const { StatusDocIdent } = useSelector((state) => state.VerifyDocident);
  const { CountState, GlobalvaluesData } = useSelector((state) => state.GlobalStateAPP);
  const classes = useStyles();
  const history = useHistory();
  const FRegularCed = new RegExp(/[0-9]{3}-?[0-9]{7}-?[0-9]$/);
  const RegetEmail = new RegExp(/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/);
  const RegetPhoneNumber = new RegExp(/^[\d*]{10,15}$/);
  const [values, handleInputChange, setValues] = useForm({
    cedula: [GlobalvaluesData?.cedula ?? "", ""],
    nombre: [GlobalvaluesData?.nombre ?? "", ""],
    apellido: [GlobalvaluesData?.apellido ?? "", ""],
    hash: [GlobalvaluesData?.hash ?? "", ""],
    telefono: [GlobalvaluesData?.telefono ?? "", ""],
    correo: [GlobalvaluesData?.correo ?? "", ""],
  });
  const { cedula} = values;
  const [ErrorCedula, setErrorCedula] = useState(null);
  const { msgError, loading } = useSelector((state) => state.uiError);
  const dispatch = useDispatch();

  const HandleResetDataForm = () => {
    const changeName = {
      ...values,
      nombre: "",
      apellido: "",
      hash: "",
      correo: "",
      telefono: "",
    };
    setValues(changeName);
    dispatch(RemoveGlobalDataValue());
  };


  const handleBack = () => {
    history.push("/");
    dispatch(RemoveGlobalDataValue());
  };



  const handleNext = () => {
      const bandera = {
      type: "Enviar",
    };
    if (ValidatorCedula(bandera)) {

        dispatch(SetActiveStep(CountState + 1));
        dispatch(SalveValuesChangeStep(values));
      }

    }


  // Validaciones del Step1

  const VerifyCedulaAdulto = (cedula) => {
    dispatch(startLoading2());
    ObtenerDataCedulaFormCedula(cedula)
        .then((data) => {
          dispatch(finishLoading2());
          const statusCode = data.status;
          dispatch(SalveRequestVerifyDocIdent(statusCode, data.data));
          const { firstName, lastName, hash, email, phone } = data.data;
          const changeName = {
            ...values,
            nombre: firstName,
            apellido: lastName,
            hash: hash,
            correo: email ? email : "",
            telefono: phone ? phone : "",
          };
          setValues(changeName);
        })
        .catch((error) => {
          dispatch(finishLoading2());
          const { message, statusCode } = JSON.parse(error.request.response);
          if(statusCode === 500){
            dispatch(error500(""))
          }
          dispatch(SalveRequestVerifyDocIdent(statusCode, message));
        });
  };

  const ValidatorCedula = (bandera) => {
    if (validator.isEmpty(cedula)) {
      if (StatusDocIdent) {
        dispatch(DeleteRequestVerifyDocIndent());
      }
      HandleResetDataForm();
      setErrorCedula("Campo requerido");
      return false;
    }
    if (!validator.isEmpty(cedula)) {
      if (!FRegularCed.test(cedula)) {
        if (StatusDocIdent) {
          dispatch(DeleteRequestVerifyDocIndent());
        }
        setErrorCedula("Formato incorrecto");
        return false;
      }
      if (bandera.type === "keyup") {
        setErrorCedula(null);
        VerifyCedulaAdulto(cedula);
      }
      if (StatusDocIdent !== 200) {
        setErrorCedula(null);
        return false;
      } else {
        setErrorCedula(null);
        return true;
      }
    }
  };








  // Declaracion de Componentes Visuales
  const fieldNameProps = {
    classes,
    value: values.nombre,
    titleName: "Nombre(s)",
    name: "nombre",
    disabled: true,
    placeholder: "Nombre",
  };

  const fieldIdProps = {
    classes,
    value: values.cedula,
    name: "cedula",
    titleName: "Número de cédula *",
    handleChange: handleInputChange,
    placeholder: "000-0000000-0",
    validation: ValidatorCedula,
    mask: TextMaskCustom,
    statusCode: StatusDocIdent,
    error: ErrorCedula,
  };



  return (
    <Box>
      <FieldIdentification {...fieldIdProps} />
      <FieldName {...fieldNameProps} />

      <Box
          my={2}
          display="flex"
          justifyContent="space-around"
          className={classes.buttons_content}
      >
        <Button
            className={classes.btn_back}
            variant="contained"
            onClick={handleBack}
        >
          Ir atrás
        </Button>
        <Button
            className={classes.button}
            variant="contained"
            onClick={handleNext}
        >
          Continuar
        </Button>
      </Box>

    </Box>
  );
};
