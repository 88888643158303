import {
  Box, Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { useStyles } from "../FormMenores.styles";
import { useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { FieldName } from "../../campos/FieldName";
import { FieldIdentification } from "../../campos/FieldIdentification";
import DateField from "../../campos/DateField";
import {
  RemoveGlobalDataFormValues,
  RemoveGlobalDataValue,
  SalveValuesChangeStep2,
  SetActiveStep
} from "../../../Redux/actions/ActionGlobalState";
import {useHistory} from "react-router";
import {useForm} from "../../../hooks/useForm";
import {DeleteRequestVerifyDocIndent, SendDataVerifyDocIdent} from "../../../Redux/actions/ActionCheckDocIdent";
import validator from "validator/es";
import { useValidatorNames} from "../../../hooks/useValidatorNames";
import { useValidator} from "../../../hooks/useValidator";

export const Step1 = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { StatusDocIdent, MessageDocIdent } = useSelector((state) => state.VerifyDocident);
  const { CountState, GlobalFormValues } = useSelector((state) => state.GlobalStateAPP);
  const history = useHistory();
  const [values, handleInputChange, , formValuesReset] = useForm({
    documento: [GlobalFormValues?.step1?.documento ?? "", ""],
    nombre: [GlobalFormValues?.step1?.nombre ??  "", ""],
    apellido: [GlobalFormValues?.step1?.apellido ?? "", ""],
    sexo: [GlobalFormValues?.step1?.sexo ?? "", ""],
  });
  const {
    documento,
    nombre,
    apellido,
    sexo
  } = values;
  const [DateNacimiento, setDateNacimiento] = useState(GlobalFormValues?.step1?.DateNacimiento ?? null);

  const handleDateNacimiento = (date) => {
    setDateNacimiento(date);
  };

// Validaciones
  const namesRegx = new RegExp(
      /^[a-zA-Z.-\u00f1\u00d1]+( +[a-zA-Z0.-\u00f1\u00d1]+)*$/
  );


  const IsvalidateDocIdent = (bandera) => {
    const docIdent = documento.toUpperCase();
    if (!validator.isEmpty(docIdent)) {
      if(bandera.type === "blur"){
        dispatch(SendDataVerifyDocIdent(docIdent));
      }
    }
    if (StatusDocIdent) {
      dispatch(DeleteRequestVerifyDocIndent());
    }
  };

  const IsvalidateDocIdentClick = () => {
    console.log(StatusDocIdent);
    if (StatusDocIdent === 200) {
      return true;
    }
    if (StatusDocIdent >= 400) {
      return false;
    } else {
      return true;
    }
  };

  const [ErrorNombre, IsValidateFirst_Name] = useValidatorNames(
      nombre,
      "Campo requerido",
      { regx: namesRegx, msg: "Este campo incluye caracteres no permitidos" }
  );
  const [ErrorApellidos, IsValidateLastName] = useValidatorNames(
      apellido,
      "Campo requerido",
      { regx: namesRegx, msg: "Este campo incluye caracteres no permitidos" }
  );
  const [ErrorSexo, ValidateIsSexo] = useValidator(
      sexo,
      "Debe seleccionar una opción"
  );

  const [ErrorFNacimiento, ValidateFNacimiento] = useValidator(
      DateNacimiento,
      "Campo requerido"
  );


  const IsFechaNacimiento = () => {
    if (DateNacimiento !== null && validator.isDate(DateNacimiento)) {
      const date = new Date();
      const NacimitnyoCon = new Date(
          DateNacimiento.toISOString().substring(0, 10)
      );

      if (NacimitnyoCon.getTime() > date.getTime()) {
        return false;
      } else {
        return true;
      }
    }
  };


// Definicion de los componentes de entrada de datos
  const fieldLastnameProps = {
    classes,
    value: values.apellido.toUpperCase(),
    titleName: "Apellido(s) del Menor *",
    name: "apellido",
    placeholder: "Apellido",
    handleChange: handleInputChange,
   validation: IsValidateLastName,
    error: ErrorApellidos
  }

  const fieldNameProps = {
    classes,
   value: values.nombre.toUpperCase(),
    titleName: "Nombre(s) del Menor *",
    name: "nombre",
    placeholder: "Nombre",
   handleChange: handleInputChange,
    validation: IsValidateFirst_Name,
    error: ErrorNombre
  }

  const fieldIdProps = {
    classes,
    value: values.documento.toUpperCase(),
    name: "documento",
    titleName: "Número del documento de identificación del Menor",
   handleChange: handleInputChange,
    placeholder: "XXXXXXXX",
   validation: IsvalidateDocIdent,
   statusCode: StatusDocIdent,
    errorMessage: MessageDocIdent
  }

  const dateFieldProps = {
    classes,
   value: DateNacimiento,
   handleChange: handleDateNacimiento,
   validation: ValidateFNacimiento,
  error: ErrorFNacimiento,
    placeholder: "dd/mm/aaaa",
    titleName: "Fecha de nacimiento del Menor *",
    disabled: false,
    identidicationData: null,
    invalidDateMessage: "Formato incorrecto",
    maxDateMessage: "La fecha de nacimiento no puede ser mayor a la fecha actual",
    minDateMessage: "Revise la fecha de nacimiento",
    KeyboardButtonProps: {
      "aria-label": "change date",
    },
    InputLabelProps: {
      shrink: true,
    },
    InputProps: {
      className: classes.inputText,
    },
    titleClass: classes.Menor,
    id: "date-picker-inline"
  }

  const HandleResetDataForm = () => {
    formValuesReset()
    setDateNacimiento(null);
    dispatch(DeleteRequestVerifyDocIndent());
    dispatch(RemoveGlobalDataValue());
  };


  const handleNext =()=>{
    if( IsvalidateDocIdentClick() &
        IsValidateFirst_Name() &
        IsValidateLastName() &
        ValidateIsSexo() &
        ValidateFNacimiento() &
        IsFechaNacimiento()) {
      values.DateNacimiento =  DateNacimiento;
      dispatch(SetActiveStep(CountState + 1));
      dispatch(SalveValuesChangeStep2({...GlobalFormValues, step1: values}));
      dispatch(SetActiveStep(CountState + 1));
    }

  }

  const handleBack = () => {
    history.push("/");
    dispatch(RemoveGlobalDataValue());
    dispatch(RemoveGlobalDataFormValues());
  };


  return (
    <Box>
      <Grid container  justifyContent="flex-end" >
        <Typography align="right"  onClick={HandleResetDataForm} className={classes.reset}>
          Limpiar campos
        </Typography>
      </Grid>

      <FieldIdentification {...fieldIdProps} />
      
      <FieldName {...fieldNameProps} />

      <FieldName {...fieldLastnameProps} />

      <FormControl variant="outlined" className={classes.select}>
        <label>
          <Typography align="left" className={classes.type_color} variant="h6">
            Sexo del Menor *
          </Typography>
        </label>
        <Select
          name="sexo"
          value={sexo}
          onChange={handleInputChange}
          onBlur={ValidateIsSexo}
          onInput={ValidateIsSexo}
          displayEmpty
        >
          {!values.sexo && (
            <MenuItem value="" disabled>
              <div className={classes.select_placeholder}>
                Seleccione su sexo
              </div>
            </MenuItem>
          )}
          <MenuItem value="F">Femenino</MenuItem>
          <MenuItem value="M">Masculino</MenuItem>
        </Select>
        {ErrorSexo && (
          <Grid>
            <Typography align="left" className={classes.MsgError}>
              {ErrorSexo}
            </Typography>
          </Grid>
        )}
      </FormControl>

      <DateField {...dateFieldProps} />

      <Box
          my={2}
          display="flex"
          justifyContent="space-around"
          className={classes.buttons_content}
      >
        <Button
            className={classes.btn_back}
            variant="contained"
            onClick={handleBack}
        >
          Ir atrás
        </Button>
        <Button
            className={classes.button}
            variant="contained"
             onClick={handleNext}
        >
        Continuar
        </Button>
      </Box>


    </Box>
  );
};
