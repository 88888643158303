import {
  Box,
  Container,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./FormStepperStyles";
import logoThree from "../../assets/images/tres-logos-fn-azul-short2.svg";
import { Step1 } from "./steps/Step1";
import "date-fns";
import { Step2 } from "./steps/Step2";
import { useSelector } from "react-redux";

function getSteps() {
  return ["Información básica", "Información de contacto"];
}
function getStepCopntent(step) {
  switch (step) {
    case 0:
      return <Step1 />;
    case 1:
      return <Step2 />;
    default:
      return "unknown step";
  }
}
export const FormStepper = () => {
  const classes = useStyles();
  const { CountState } = useSelector((state) => state.GlobalStateAPP);
  const steps = getSteps();

  return (
    <Container className={classes.content}>
      <Box>
        <img src={logoThree} alt="logo" className={classes.logo} />{" "}
      </Box>
      <Typography
        align="center"
        variant="h6"
        component="h1"
        m={1}
        className={classes.title}
      >
        Solicitud de Certificado de Vacunación
      </Typography>
      <Typography
        align="center"
        variant="h6"
        component="h1"
        className={classes.redLetter}
      >
        para Adultos con pasaporte
      </Typography>
      <Box>
        <Stepper alternativeLabel activeStep={CountState}>
          {steps.map((step, index) => {
            const labelProps = {};
            const stepProps = {};

            return (
              <Step {...stepProps} key={index}>
                <StepLabel {...labelProps}>{step}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <form>{getStepCopntent(CountState)}</form>
      </Box>
    </Container>
  );
};
