import {
    FormControl,
    Grid,
    TextField,
    Typography,
  } from "@material-ui/core";

const CardCodeField = (props) => {
    const {
        classes,
        value,
        handleChange,
        validation,
        error,
        placeholder,
        name,
        titleName,
        disabled,
        mask,
        readOnly,
        maxLength,
    } = props;


    return (
        <FormControl className={classes.select}>
        <label>
          <Typography
            align="left"
            className={classes.type_color}
            variant="h6"
          >
            {titleName}
          </Typography>
        </label>
        <TextField
          variant="outlined"
          disabled={disabled}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={validation}
          onKeyUp={validation}
          InputProps={{
            inputComponent: mask,
            readOnly: readOnly
          }}
          inputProps={{
            maxLength: maxLength,
            className: classes.tarject
          }}
          className={disabled ? classes.borderStyle : ""}
        />
        {error && (
            <Grid>
              <Typography align="left" className={classes.MsgError}>
                {error}
              </Typography>
            </Grid>
        )}
      </FormControl>
    )
};

export default CardCodeField;