import { useState } from "react";

export const useDate = (initialState = {}) => {

  const mapObject = (positionNumber) => {
    let objectMapped = {}
    Object.keys(initialState).forEach((key) => {
      objectMapped[key] = initialState[key][positionNumber]
    })
    return objectMapped;
  }

  const [Values, setValues] = useState(mapObject(0));
  
  const reset = () => {
    setValues(mapObject(1));
  };

  const handleInputChange = (event, name) => {
    setValues((values) => ({
      ...values,
      [name]: event
    }))
  };

  return [Values, handleInputChange, reset, setValues];
};
