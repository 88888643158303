import { useState } from "react";

export const useForm = (initialState = {}) => {
  
  const mapObject = (positionNumber) => {
    let objectMapped = {}
    Object.keys(initialState).forEach((key) => {
      objectMapped[key] = initialState[key][positionNumber]
    })
    return objectMapped;
  }

  const [Values, setValues] = useState(mapObject(0));

  const reset = () => {
    setValues(mapObject(1));
  };

  const handleInputChange = (event) => {
    if(event.target.name === "sameTarjectCode" || event.target.name === "sameTutorInfo"){
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.checked,
      }));
    }else{
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value,
      }));
    }
  };

  return [Values, handleInputChange, setValues, reset];
};
