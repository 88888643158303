import {
  Box,
  Button,
  Grid,
  Typography,
} from "@material-ui/core";
import { useStyles } from "../FormMenores.styles";
import { TextMaskCustom } from "../../../util/testMask";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteRequestVerifyDocIndent,
  SalveCedulaTutor,
} from "../../../Redux/actions/ActionCheckDocIdent";
import { FieldName } from "../../campos/FieldName";
import { TipeDocument } from "../../campos/TipeDocument";
import { FieldIdentification } from "../../campos/FieldIdentification";
import EmailInputField from "../../campos/EmailInputField";
import PhoneNumberField from "../../campos/PhoneNumberField";
import {
  SalveValuesChangeStep2,
  SetActiveStep,
} from "../../../Redux/actions/ActionGlobalState";
import { useForm } from "../../../hooks/useForm";
import { useState } from "react";
import { error500, finishLoading2, startLoading2 } from "../../../Redux/actions/UIError";
import { SendVerifyCedula } from "../../../Axios/sendForm";
import { useValidatorNames } from "../../../hooks/useValidatorNames";
import validator from "validator/es";
import { useValidator } from "../../../hooks/useValidator";
export const Step2 = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { status, cedulaTutor } = useSelector((state) => state.VerifyDocident);
  const { CountState, GlobalFormValues } = useSelector(
    (state) => state.GlobalStateAPP
  );

  const [formValues, handleInputChange, setValues, formValuesReset] = useForm({
    nombreTutor: [GlobalFormValues?.step2?.nombreTutor ?? "", ""],
    DocIdentifitutor: [GlobalFormValues?.step2?.DocIdentifitutor ?? "", ""],
    numeroTutor: [GlobalFormValues?.step2?.numeroTutor ?? "", ""],
    correoTutor: [GlobalFormValues?.step2?.correoTutor ?? "", ""],
    TipoIdentTutor: [GlobalFormValues?.step2?.TipoIdentTutor ?? "CEDULA","CEDULA"]
  });

  const [ErrorTipoDocIden, setErrorTipoDocIden] = useState(null);
  const { nombreTutor, DocIdentifitutor, numeroTutor, correoTutor } = formValues;
  const [ErrorDocIdent, setErrorDocIdent] = useState(null);
  const [ErrorPhoneNumber, setErrorPhoneNumber] = useState(null);

  const HandleResetDataForm = () => {
    formValuesReset();
    dispatch(DeleteRequestVerifyDocIndent());
    dispatch(SalveValuesChangeStep2({ ...GlobalFormValues, step2: {} }));
  };

  const handleNamesTutor = (TargetName, fullName) => {
    if (TargetName === "DocIdentifitutor") {
      const changeName = {
        ...formValues,
        nombreTutor: fullName,
      };
      setValues(changeName);
    }
  };

  const VerifyCedulaTutor = (TargetName, cedula) => {
    return new Promise((response, reject) => {
      dispatch(startLoading2());
      SendVerifyCedula(cedula)
        .then((data) => {
          dispatch(finishLoading2());
          const status = data.status;
          const names = data.data.payload.names;
          const fullName = names;
          handleNamesTutor(TargetName, fullName);
          response(status);
          dispatch(SalveCedulaTutor(status, cedula));
        })
        .catch((error) => {
          if(error.response.status === 404 || error.response.status === 500){
            dispatch(error500(""))
          }
          dispatch(finishLoading2());
        });
    });
  };

  //Validaciones Step 2
  const namesRegx = new RegExp(
    /^[a-zA-Z.-\u00f1\u00d1]+( +[a-zA-Z0.-\u00f1\u00d1]+)*$/
  );
  const RegetEmail = new RegExp(/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/);
  const FRegularCed = new RegExp(/[0-9]{3}-?[0-9]{7}-?[0-9]$/);
  const FregularPasp = new RegExp(/^([A-Z0-9]){3,20}$/);
  const RegetPhoneNumber = new RegExp(/^\d{10,15}$/);

  const [ErrorFullName, IsValidateFullName] = useValidatorNames(
    nombreTutor,
    "Campo requerido",
    { regx: namesRegx, msg: "Este campo incluye caracteres no permitidos" }
  );

  const ValidateIsTipoDoc = () => {
    if (formValues.TipoIdentTutor === "") {
      setErrorTipoDocIden("Debe seleccionar una opción");
      return false;
    } else {
      setErrorTipoDocIden(null);
      return true;
    }
  };
  const ValidatorDocIdent = (bandera) => {
    const docIden = DocIdentifitutor.toUpperCase();
    if (validator.isEmpty(DocIdentifitutor)) {
      if (cedulaTutor) {
        const changeName = {
          ...formValues,
          nombreTutor: "",
        };
        setValues(changeName);
        dispatch(DeleteRequestVerifyDocIndent());
      }

      setErrorDocIdent("Campo requerido");
      return false;
    }
    if (!validator.isEmpty(DocIdentifitutor) && formValues.TipoIdentTutor === "CEDULA") {
      if (!FRegularCed.test(DocIdentifitutor)) {
        if (nombreTutor !== "") {
          const changeName = {
            ...formValues,
            nombreTutor: "",
          };
          setValues(changeName);
        }
        setErrorDocIdent("Formato incorrecto");
        return false;
      }
      if (bandera.type === "keyup") {
        const TargetName = "DocIdentifitutor";
        VerifyCedulaTutor(TargetName, DocIdentifitutor).then((status) => {
          dispatch(SalveCedulaTutor(status, DocIdentifitutor));
          setErrorDocIdent(null);
          return true;
        });
      } else {
        setErrorDocIdent(null);
        return true;
      }
    }

    if (!validator.isEmpty(docIden) && formValues.TipoIdentTutor === "PASAPORTE") {
      if (!FregularPasp.test(docIden)) {
        setErrorDocIdent("Formato incorrecto");
        return false;
      } else {
        setErrorDocIdent(null);
        return true;
      }
    } else {
      setErrorDocIdent(null);
      return true;
    }
  };

  const IsValidatePhoneNumber = () => {
    if (!validator.isEmpty(numeroTutor)) {
      if (!validator.isNumeric(numeroTutor)) {
        setErrorPhoneNumber("Caracteres no permitidos");
        return false;
      }
      if (!RegetPhoneNumber.test(numeroTutor)) {
        setErrorPhoneNumber("Formato incorrecto");
        return false;
      } else {
        setErrorPhoneNumber(null);
        return true;
      }
    } else {
      setErrorPhoneNumber(null);
      return true;
    }
  };

  const [ErrorEmail, isValidatorEmail] = useValidator(
    correoTutor,
    "Campo requerido",
    { regx: RegetEmail, msg: "Formato incorrecto" }
  );

  //Definicion de los Componentes de entrada de datos

  function handleGroupChange(name) {
    setValues((values) => ({
      ...values,
      nombreTutor: "",
      DocIdentifitutor: "",
    }));
    dispatch(DeleteRequestVerifyDocIndent());
    setErrorDocIdent(null);
  }

  const fieldNameProps = {
    classes,
    value: nombreTutor.toUpperCase(),
    titleName: "Nombre del Tutor *",
    name: "nombreTutor",
    disabled: status === 200 ? true : false,
    placeholder: "Nombre del tutor",
    handleChange: handleInputChange,
    validation: IsValidateFullName,
    identidicationData: cedulaTutor,
    error: ErrorFullName,
  };

  const emailFieldProps = {
    classes,
    value: correoTutor,
    handleChange: handleInputChange,
    validation: isValidatorEmail,
    error: ErrorEmail,
    placeholder: "ejemplo@gmail.com",
    name: "correoTutor",
    titleName: "Correo electrónico del Tutor *",
    disabled: false,
  };

  const phoneNumberFieldProps = {
    classes,
    value: numeroTutor,
    handleChange: handleInputChange,
    validation: IsValidatePhoneNumber,
    error: ErrorPhoneNumber,
    placeholder: "Ej.: 8295555555",
    name: "numeroTutor",
    titleName: "Número de teléfono del Tutor",
  };

  const tipeDocumentProps = {
    classes,
    value: formValues.TipoIdentTutor,
    handleChange: handleInputChange,
    handleReset: handleGroupChange,
    validation: ValidateIsTipoDoc,
    error: ErrorTipoDocIden,
    name: "TipoIdentTutor",
    titleName: "Tipo de documento de identificación del Tutor: *",
    nameFields: "DocIdentifitutor",
    valueControlCed: "CEDULA",
    labelControlCed: "Cédula",
    valueControlPas: "PASAPORTE",
    labelControlPas: "Pasaporte",
  };

  const fieldCedulaProps = {
    classes,
    value: DocIdentifitutor,
    name: "DocIdentifitutor",
    titleName: "Número de Cédula del Tutor *",
    handleChange: handleInputChange,
    placeholder: "000-0000000-0",
    validation: ValidatorDocIdent,
    mask: TextMaskCustom,
    error: ErrorDocIdent,
  };

  const fieldPassportProps = {
    classes,
    value: DocIdentifitutor.toUpperCase(),
    name: "DocIdentifitutor",
    titleName: "Número de Pasaporte del Tutor *",
    handleChange: handleInputChange,
    placeholder: "Ej.: RD123456",
    validation: ValidatorDocIdent,
    error: ErrorDocIdent,
  };

  const handleNext = () => {
    const bandera = {
      type: "Enviar",
    };
    if (
      IsValidateFullName() &
      ValidateIsTipoDoc() &
      ValidatorDocIdent(bandera) &
      IsValidatePhoneNumber() &
      isValidatorEmail()
    ) {
      dispatch(
        SalveValuesChangeStep2({
          ...GlobalFormValues,
          step2: { ...formValues },
        })
      );
      dispatch(DeleteRequestVerifyDocIndent());
      dispatch(SetActiveStep(CountState + 1));
    }
  };

  const handleBack = () => {
    dispatch(
      SalveValuesChangeStep2({
        ...GlobalFormValues,
        step2: { ...formValues },
      })
    );
    dispatch(SetActiveStep(CountState - 1));
  };

  return (
    <Box>
      <Alert severity="info">
        <AlertTitle>
          Se debe introducir la información del tutor del menor; la persona que
          recibirá instrucciones para obtener el Certificado de Vacunación del
          menor.
        </AlertTitle>
      </Alert>
      <Grid container justifyContent="flex-end">
        <Typography
          align="right"
          onClick={HandleResetDataForm}
          className={classes.reset}
        >
          Limpiar campos
        </Typography>
      </Grid>

      <TipeDocument {...tipeDocumentProps} />
      {formValues.TipoIdentTutor === "CEDULA" && (
        <FieldIdentification {...fieldCedulaProps} />
      )}
      {formValues.TipoIdentTutor === "PASAPORTE" && (
        <FieldIdentification {...fieldPassportProps} />
      )}

      <FieldName {...fieldNameProps} />

      <EmailInputField {...emailFieldProps} />
      <PhoneNumberField {...phoneNumberFieldProps} />

      <Alert severity="info">
        <AlertTitle>
          Por esta vía se le estará enviando instrucciones para obtener el
          Certificado de Vacunación del menor.
        </AlertTitle>
      </Alert>

      <Box
        my={2}
        display="flex"
        justifyContent="space-around"
        className={classes.buttons_content}
      >
        <Button
          className={classes.btn_back}
          variant="contained"
          onClick={handleBack}
        >
          Ir atrás
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          onClick={handleNext}
        >
          Continuar
        </Button>
      </Box>
    </Box>
  );
};
