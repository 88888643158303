const TYPES = {
  places: "PLACES",
  RemovePlaces:"RemovePlaces",

  center: "CENTER",
  RemoveCenter: "REMOVECENTER",

  SaveAdultSinCedula: "SaveAdultSinCedula",
  RemoveAdultSinCedula:"RemoveAdultSinCedula",

  SaveRequestVerifyDoc: "SaveRequestVerifyDoc",
  RemoveRequestVerifyDoc: "RemoveRequestVerifyDoc",

  SavecedulaTutor: "SavecedulaTutor",
  RemoveCedulaTutor:"RemoveCedulaTutor",

  SaveCedula1: "SaveCedula1",
  RemoveSaveCedula1:"RemoveSaveCedula1",

  SaveCedula2: "SaveCedula2",
  RemoveSaveCedula2:"RemoveSaveCedula2",

  SaveCedula3: "SaveCedula3",
  RemoveSaveCedula3:"RemoveSaveCedula3",

  SaveCedula4: "SaveCedula4",
  RemoveSaveCedula4: "RemoveSaveCedula4",

  UISetError: "UISetError",
  UIRemoveError: "UIRemoveError",

  uiStartLoading: "uiStartLoading",
  uiFinishLoading: "uiFinishLoading",

  uiStartLoading2:"uiStartLoading2",
  uiFinishLoading2:"uiFinishLoading2",

  uiError500: "uiError500",
  removeError500: "removeError500",

  strapiData: "StrapiData",
  strapiError : "StrapiError",

  //Variables Globales
  ActiveStep:"ActiveStep",
  RemoveActiveStep:"RemoveActiveStep",
  ChangeStep:"ChangeStep",
  RemoveGlobaldataValue:"RemoveGlobaldataValue",

  ChangeStep2:"ChangeStep2",
  RemoveGlobalFormValue:"RemoveGlobalFormValue"

};

export { TYPES };
