
import { Box, Container, Modal, Typography } from "@material-ui/core";
import { useStyles } from "./layout.styles";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeError500 } from "../../Redux/actions/UIError";
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

export const Layout = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();
  const { error500 } = useSelector((state) => state.uiError)
  const [isError500, setIsError500] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [location.pathname]);

  useEffect(() => {
    if(error500){
      setIsError500(true)
    }
  }, [error500])

  const handleCloseModal = () => {
    setIsError500(false)
    dispatch(removeError500())
  }

  return (
    <div className={classes.main}>
      <Container className={classes.container}>{children}</Container>
      <Modal className={classes.modal_error} open={isError500}>
        <Box className={classes.modal_box}>          
          <ErrorOutlineIcon className={classes.error_icon} />
          <Typography className={classes.modal_text}>
            {error500}
          </Typography>
          <CloseIcon onClick={handleCloseModal} className={classes.close_icon} />
        </Box>
      </Modal>
    </div>
  );
};
