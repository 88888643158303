import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import { useStyles } from "../../FormStepper/FormStepperStyles";
import "date-fns";
import validator from "validator/es";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { TextMaskCustom } from "../../../util/testMask";
import { useGetCenter } from "../../../hooks/useGetCenter";
import {
  DeleteCedula1,
  DeleteCedula2,
  DeleteCedula3,
  DeleteCedula4,
  DeleteRequestVerifyDocIndent,
  SalveCedula1,
  SalveCedula2,
  SalveCedula3,
  SalveCedula4,
} from "../../../Redux/actions/ActionCheckDocIdent";
import { TarjectMask } from "../../../util/tarjectCodeMask";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { FieldName } from "../../campos/FieldName";
import { TipeDocument } from "../../campos/TipeDocument";
import { FieldIdentification } from "../../campos/FieldIdentification";
import DateField from "../../campos/DateField";
import { ProvinceField } from "../../campos/ProvinceField";
import { TownField } from "../../campos/TownField";
import CardCodeField from "../../campos/CardCodeField";
import { CenterField } from "../../campos/CenterField";
import {
  SalveValuesChangeStep2,
  SetActiveStep,
} from "../../../Redux/actions/ActionGlobalState";
import { useForm } from "../../../hooks/useForm";
import { useValidateTarjeta, useValidator } from "../../../hooks/useValidator";
import { SendVerifyCedula } from "../../../Axios/sendForm";
import { useValidatorNames } from "../../../hooks/useValidatorNames";
import { error500, finishLoading2, startLoading2 } from "../../../Redux/actions/UIError";
import { RequestDataFormMenores } from "../../../Redux/actions/ActionDataForm";
import { useShowHide } from "../../../hooks/useShowHide";
import { useDate } from "../../../hooks/useDate";

export const Step3 = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { CountState, GlobalFormValues } = useSelector(
    (state) => state.GlobalStateAPP
  );

  const { provincias, municipios } = useSelector((state) => state.CountryData);
  const { loading } = useSelector((state) => state.uiError);

  const [values, handleVaccineChange, setvaccineValues, vaccineValuesReset] =
    useForm({
      municipio1: [GlobalFormValues?.step3?.municipio1 ?? "", ""],
      provincia1: [GlobalFormValues?.step3?.provincia1 ?? "", ""],
      centro1: [GlobalFormValues?.step3?.centro1 ?? "", ""],
      cedula1: [GlobalFormValues?.step3?.cedula1 ?? "", ""],
      nombre1: [GlobalFormValues?.step3?.nombre1 ?? "", ""],
      tarjeta1: [GlobalFormValues?.step3?.tarjeta1 ?? "", ""],
      provincia2: [GlobalFormValues?.step3?.provincia2 ?? "", ""],
      municipio2: [GlobalFormValues?.step3?.municipio2 ?? "", ""],
      centro2: [GlobalFormValues?.step3?.centro2 ?? "", ""],
      cedula2: [GlobalFormValues?.step3?.cedula2 ?? "", ""],
      nombre2: [GlobalFormValues?.step3?.nombre2 ?? "", ""],
      tarjeta2: [GlobalFormValues?.step3?.tarjeta2 ?? "", ""],
      provincia3: [GlobalFormValues?.step3?.provincia3 ?? "", ""],
      municipio3: [GlobalFormValues?.step3?.municipio3 ?? "", ""],
      centro3: [GlobalFormValues?.step3?.centro3 ?? "", ""],
      cedula3: [GlobalFormValues?.step3?.cedula3 ?? "", ""],
      nombre3: [GlobalFormValues?.step3?.nombre3 ?? "", ""],
      tarjeta3: [GlobalFormValues?.step3?.tarjeta3 ?? "", ""],
      provincia4: [GlobalFormValues?.step3?.provincia4 ?? "", ""],
      municipio4: [GlobalFormValues?.step3?.municipio4 ?? "", ""],
      centro4: [GlobalFormValues?.step3?.centro4 ?? "", ""],
      cedula4: [GlobalFormValues?.step3?.cedula4 ?? "", ""],
      nombre4: [GlobalFormValues?.step3?.nombre4 ?? "", ""],
      tarjeta4: [GlobalFormValues?.step3?.tarjeta4 ?? "", ""],
      sameTarjectCode: [GlobalFormValues?.step3?.sameTarjectCode ?? false, false],
      sameTutorInfo: [GlobalFormValues?.step3?.sameTutorInfo ?? false, false],
    });

  const [dateValues, handleDateChange, datesReset] = useDate({
    selectedDate1: [GlobalFormValues?.step3?.dates?.selectedDate1 ?? null, null],
    selectedDate2: [GlobalFormValues?.step3?.dates?.selectedDate2 ?? null, null],
    selectedDate3: [GlobalFormValues?.step3?.dates?.selectedDate3 ?? null, null],
    selectedDate4: [GlobalFormValues?.step3?.dates?.selectedDate4 ?? null, null],
  });

  const [cedPasValues, handleCedPasChange, ,cedPasValuesReset] = useForm({
    TipoIdent1: [GlobalFormValues?.step3?.idTypes?.TipoIdent1 ?? "CEDULA", "CEDULA"],
    TipoIdent2: [GlobalFormValues?.step3?.idTypes?.TipoIdent2 ?? "CEDULA", "CEDULA"],
    TipoIdent3: [GlobalFormValues?.step3?.idTypes?.TipoIdent3 ?? "CEDULA", "CEDULA"],
    TipoIdent4: [GlobalFormValues?.step3?.idTypes?.TipoIdent4 ?? "CEDULA", "CEDULA"],
  });

  const [centrosValues, handleCentros, , centrosReset] = useForm({
    centro1: [GlobalFormValues?.step3?.centros?.centro1 ?? "", ""],
    centro2: [GlobalFormValues?.step3?.centros?.centro2 ?? "", ""],
    centro3: [GlobalFormValues?.step3?.centros?.centro3 ?? "", ""],
    centro4: [GlobalFormValues?.step3?.centros?.centro4 ?? "", ""],
  });

  const [showHideValues, handleShowHide, showHideReset] = useShowHide({
    show1: true,
    show2: true,
    show3: false,
    show4: false,
  });

  const [ErrorDocIdent1, setErrorDocIdent1] = useState(null);
  const [ErrorDocIdent2, setErrorDocIdent2] = useState(null);
  const [ErrorDocIdent3, setErrorDocIdent3] = useState(null);
  const [ErrorDocIdent4, setErrorDocIdent4] = useState(null);

  const [centros1, setCentros1] = useState([]);
  const [centros2, setCentros2] = useState([]);
  const [centros3, setCentros3] = useState([]);
  const [centros4, setCentros4] = useState([]);

  const [errorCentro1, setErrorCentro1] = useState("");
  const [errorCentro2, setErrorCentro2] = useState("");
  const [errorCentro3, setErrorCentro3] = useState("");
  const [errorCentro4, setErrorCentro4] = useState("");

  const [ErrorTipoDocIden1, setErrorTipoDocIden1] = useState(null);
  const [ErrorTipoDocIden2, setErrorTipoDocIden2] = useState(null);
  const [ErrorTipoDocIden3, setErrorTipoDocIden3] = useState(null);
  const [ErrorTipoDocIden4, setErrorTipoDocIden4] = useState(null);

  const [ErrordateDosis2, setErrordateDosis2] = useState(null);


  const namesRegx = new RegExp(/^[a-zA-Z.-\u00f1\u00d1]+( +[a-zA-Z0.-\u00f1\u00d1]+)*$/);
  const cedulaRegex = new RegExp(/[0-9]{3}-?[0-9]{7}-?[0-9]$/);
  const passportRegex = new RegExp(/^([A-Z0-9]){3,20}$/);
  const CodigoTarjetaRegx = /^A-([1-9]|[B-DF-HJ-NP-TV-Z]){3}-([1-9]|[B-DF-HJ-NP-TV-Z]){3}-([1-9]|[B-DF-HJ-NP-TV-Z]){3}/;

  const resetTown = (name) => {
    handleVaccineChange({
      target: {
        name,
        value: ""
      }
    })
  }

  const resetCenter = (name) => {
    handleCentros({
      target: {
        name,
        value: ""
      }
    })
  }

  const handleNamesTutor = (TargetName, fullName) => {
    if (TargetName === "cedula1") {
      const changeName = {
        ...values,
        nombre1: fullName,
      };
      setvaccineValues(changeName);
    }
    if (TargetName === "cedula2") {
      const changeName = {
        ...values,
        nombre2: fullName,
      };
      setvaccineValues(changeName);
    }

    if (TargetName === "cedula3") {
      const changeName = {
        ...values,
        nombre3: fullName,
      };
      setvaccineValues(changeName);
    }

    if (TargetName === "cedula4") {
      const changeName = {
        ...values,
        nombre4: fullName,
      };
      setvaccineValues(changeName);
    }
  };

  const VerifyCedulaTutor = (TargetName, cedula) => {
    return new Promise((response, reject) => {
      dispatch(startLoading2());
      SendVerifyCedula(cedula)
        .then((data) => {
          dispatch(finishLoading2());
          const status = data.status;
          const names = data.data.payload.names;
          const fullName = names;
          handleNamesTutor(TargetName, fullName);
          response(status);
        })
        .catch((error) => {
          if(error.response.status === 404 || error.response.status === 500){
            dispatch(error500(""))
          }
          dispatch(finishLoading2());
        });
    });
  };

  const ValidateIsTipoDoc1 = () => {
    if (cedPasValues.TipoIdent1 === "") {
      setErrorTipoDocIden1("Debe seleccionar una opción");
      return false;
    } else {
      setErrorTipoDocIden1(null);
      return true;
    }
  };

  const ValidatorDocIdent1 = (bandera) => {
    const pasaporte1 = values.cedula1.toUpperCase();
    if (validator.isEmpty(values.cedula1)) {
      if (values.nombre1 !== "") {
        const changeName = {
          ...values,
          nombre1: "",
        };
        setvaccineValues(changeName);
        dispatch(DeleteCedula1());
      }
      setErrorDocIdent1("Campo requerido");
      return false;
    }
    if (
      !validator.isEmpty(values.cedula1) &&
      cedPasValues.TipoIdent1 === "CEDULA"
    ) {
      if (!cedulaRegex.test(values.cedula1)) {
        if (values.nombre1 !== "") {
          const changeName = {
            ...values,
            nombre1: "",
          };
          setvaccineValues(changeName);
          dispatch(DeleteCedula1());
        }
        setErrorDocIdent1("Formato incorrecto");
        return false;
      }
      if (bandera.type === "keyup") {
        const TargetName = "cedula1";
        VerifyCedulaTutor(TargetName, values.cedula1).then((status) => {
          dispatch(SalveCedula1(status, values.cedula1));
          setErrorDocIdent1(null);
          return true;
        });
      } else {
        IsValidateName1();
        setErrorDocIdent1(null);
        return true;
      }
    }
    if (
      !validator.isEmpty(pasaporte1) &&
      cedPasValues.TipoIdent1 === "PASAPORTE"
    ) {
      if (!passportRegex.test(pasaporte1)) {
        setErrorDocIdent1("Formato incorrecto");
        return false;
      } else {
        setErrorDocIdent1(null);
        return true;
      }
    } else {
      setErrorDocIdent1(null);
      return true;
    }
  };

  const ValidateIsTipoDoc2 = () => {
    if (values.sameTutorInfo) return true;
    if (cedPasValues.TipoIdent2 === "") {
      setErrorTipoDocIden2("Debe seleccionar una opción");
      return false;
    } else {
      setErrorTipoDocIden2(null);
      return true;
    }
  };

  const ValidatorDocIdent2 = (bandera) => {
    if (values.sameTutorInfo) return true;
    const pasaporte2 = values.cedula2.toUpperCase();
    if (validator.isEmpty(values.cedula2)) {
      if (values.nombre2 !== "") {
        const changeName = {
          ...values,
          nombre2: "",
        };
        setvaccineValues(changeName);
        dispatch(DeleteCedula2());
      }

      setErrorDocIdent2("Campo requerido");
      return false;
    }
    if (
      !validator.isEmpty(values.cedula2) &&
      cedPasValues.TipoIdent2 === "CEDULA"
    ) {
      if (!cedulaRegex.test(values.cedula2)) {
        if (values.nombre2 !== "") {
          const changeName = {
            ...values,
            nombre2: "",
          };
          setvaccineValues(changeName);
          dispatch(DeleteCedula2());
        }
        setErrorDocIdent2("Formato incorrecto");
        return false;
      }
      if (bandera.type === "keyup") {
        const TargetName = "cedula2";
        VerifyCedulaTutor(TargetName, values.cedula2).then((status) => {
          dispatch(SalveCedula2(status, values.cedula2));
          setErrorDocIdent2(null);
          return true;
        });
      } else {
        IsValidateName2();
        setErrorDocIdent2(null);
        return true;
      }
    }
    if (
      !validator.isEmpty(pasaporte2) &&
      cedPasValues.TipoIdent2 === "PASAPORTE"
    ) {
      if (!passportRegex.test(pasaporte2)) {
        setErrorDocIdent2("Formato incorrecto");
        return false;
      } else {
        setErrorDocIdent2(null);
        return true;
      }
    } else {
      setErrorDocIdent2(null);
      return true;
    }
  };

  const ValidateIsTipoDoc3 = () => {
    if (values.sameTutorInfo) return true;
    if (cedPasValues.TipoIdent3 === "" && values.provincia3 !== "") {
      setErrorTipoDocIden3("Debe seleccionar una opción");
      return false;
    } else {
      setErrorTipoDocIden3(null);
      return true;
    }
  };

  const ValidatorDocIdent3 = (bandera) => {
    if (values.sameTutorInfo) return true;
    const pasaporte3 = values.cedula3.toUpperCase();
    if (validator.isEmpty(values.cedula3) && values.provincia3 !== "") {
      if (values.nombre3 !== "") {
        const changeName = {
          ...values,
          nombre3: "",
        };
        setvaccineValues(changeName);
        dispatch(DeleteCedula3());
      }
      setErrorDocIdent3("Campo requerido");
      return false;
    }
    if (
      !validator.isEmpty(values.cedula3) &&
      cedPasValues.TipoIdent3 === "CEDULA"
    ) {
      if (!cedulaRegex.test(values.cedula3)) {
        if (values.nombre3 !== "") {
          const changeName = {
            ...values,
            nombre3: "",
          };
          setvaccineValues(changeName);
          dispatch(DeleteCedula3());
        }
        setErrorDocIdent3("Formato incorrecto");
        return false;
      }
      if (bandera.type === "keyup") {
        const TargetName = "cedula3";
        VerifyCedulaTutor(TargetName, values.cedula3).then((status) => {
          dispatch(SalveCedula3(status, values.cedula3));
          setErrorDocIdent3(null);
          return true;
        });
      } else {
        IsValidateName3();
        setErrorDocIdent3(null);
        return true;
      }
    }
    if (
      !validator.isEmpty(pasaporte3) &&
      cedPasValues.TipoIdent3 === "PASAPORTE"
    ) {
      if (!passportRegex.test(pasaporte3)) {
        setErrorDocIdent3("Formato incorrecto");
        return false;
      } else {
        setErrorDocIdent3(null);
        return true;
      }
    } else {
      setErrorDocIdent3(null);
      return true;
    }
  };

  const ValidateIsTipoDoc4 = () => {
    if (values.sameTutorInfo) return true;
    if (cedPasValues.TipoIdent4 === "" && values.provincia4 !== "") {
      setErrorTipoDocIden4("Debe seleccionar una opción");
      return false;
    } else {
      setErrorTipoDocIden4(null);
      return true;
    }
  };

  const ValidatorDocIdent4 = (bandera) => {
    if (values.sameTutorInfo) return true;
    const pasaporte4 = values.cedula4.toUpperCase();
    if (validator.isEmpty(values.cedula4) && values.provincia4 !== "") {
      if (values.nombre4 !== "") {
        const changeName = {
          ...values,
          nombre4: "",
        };
        setvaccineValues(changeName);
        dispatch(DeleteCedula4());
      }
      setErrorDocIdent4("Campo requerido");
      return false;
    }
    if (
      !validator.isEmpty(values.cedula4) &&
      cedPasValues.TipoIdent4 === "CEDULA"
    ) {
      if (!cedulaRegex.test(values.cedula4)) {
        if (values.nombre4 !== "") {
          const changeName = {
            ...values,
            nombre4: "",
          };
          setvaccineValues(changeName);
          dispatch(DeleteCedula4());
        }
        setErrorDocIdent4("Formato incorrecto");
        return false;
      }
      if (bandera.type === "keyup") {
        const TargetName = "cedula4";
        VerifyCedulaTutor(TargetName, values.cedula4).then((status) => {
          dispatch(SalveCedula4(status, values.cedula3));
          setErrorDocIdent4(null);
          return true;
        });
      } else {
        IsValidateName4();
        setErrorDocIdent4(null);
        return true;
      }
    }
    if (
      !validator.isEmpty(pasaporte4) &&
      cedPasValues.TipoIdent4 === "PASAPORTE"
    ) {
      if (!passportRegex.test(pasaporte4)) {
        setErrorDocIdent4("Formato incorrecto");
        return false;
      } else {
        setErrorDocIdent4(null);
        return true;
      }
    } else {
      setErrorDocIdent4(null);
      return true;
    }
  };

  const [ErrorProvincia1, ValidateIsProvincia1] = useValidator(
    values.provincia1,
    "Debe seleccionar una opción"
  );
  const [ErrorProvincia2, ValidateIsProvincia2] = useValidator(
    values.provincia2,
    "Debe seleccionar una opción"
  );

  const [ErrorMunicipio1, ValidateIsMunicipio1] = useValidator(
    values.municipio1,
    "Debe seleccionar una opción"
  );
  const [ErrorMunicipio2, ValidateIsMunicipio2] = useValidator(
    values.municipio2,
    "Debe seleccionar una opción"
  );
  const [ErrorMunicipio3, ValidateIsMunicipio3] = useValidator(
    values.municipio3,
    "Debe seleccionar una opción",
    { dependence: values.provincia3 }
  );
  const [ErrorMunicipio4, ValidateIsMunicipio4] = useValidator(
    values.municipio4,
    "Debe seleccionar una opción",
    { dependence: values.provincia4 }
  );

  const [ErrorCentro1, ValidateIsCentro1] = useValidator(
    values.centro1,
    "Debe seleccionar una opción"
  );
  const [ErrorCentro2, ValidateIsCentro2] = useValidator(
    values.centro2,
    "Debe seleccionar una opción"
  );
  const [ErrorCentro3, ValidateIsCentro3] = useValidator(
    values.centro3,
    "Debe seleccionar una opción",
    { dependence: values.provincia3 }
  );
  const [ErrorCentro4, ValidateIsCentro4] = useValidator(
    values.centro4,
    "Debe seleccionar una opción",
    { dependence: values.provincia4 }
  );

  const [ErrorNombre1, IsValidateName1] = useValidatorNames(
    values.nombre1,
    "Campo requerido",
    { regx: namesRegx, msg: "Este campo incluye caracteres no permitidos" }
  );
  const [ErrorNombre2, IsValidateName2] = useValidatorNames(
    values.nombre2,
    "Campo requerido",
    {
      regx: namesRegx,
      msg: "Este campo incluye caracteres no permitidos",
      free: values.sameTutorInfo,
    }
  );
  const [ErrorNombre3, IsValidateName3] = useValidatorNames(
    values.nombre3,
    "Campo requerido",
    {
      regx: namesRegx,
      msg: "Este campo incluye caracteres no permitidos",
      dependence: values.provincia3,
      free: values.sameTutorInfo,
    }
  );

  const [ErrorNombre4, IsValidateName4] = useValidatorNames(
    values.nombre4,
    "Campo requerido",
    {
      regx: namesRegx,
      msg: "Este campo incluye caracteres no permitidos",
      dependence: values.provincia4,
      free: values.sameTutorInfo,
    }
  );

  const [Errorfecha1, IsValidatorFecha1] = useValidator(
    dateValues.selectedDate1,
    "Campo requerido"
  );
  const [Errorfecha2, IsValidatorFecha2] = useValidator(
    dateValues.selectedDate2,
    "Campo requerido"
  );
  const [Errorfecha3, IsValidatorFecha3] = useValidator(
    dateValues.selectedDate3,
    "Campo requerido",
    { dependence: values.provincia3 }
  );
  const [Errorfecha4, IsValidatorFecha4] = useValidator(
    dateValues.selectedDate4,
    "Campo requerido",
    { dependence: values.provincia4 }
  );

  const ValidateDateDosis = () => {
    if (
      dateValues.selectedDate1 !== null &&
      dateValues.selectedDate2 !== null
    ) {
      if (dateValues.selectedDate1 >= dateValues.selectedDate2) {
        setErrordateDosis2("Las dosis presentan fechas incoherentes");
        //  setErrordateDosis3(null);
        return false;
      }
      if (
        values.provincia3 !== "" &&
        dateValues.selectedDate2 >= dateValues.selectedDate3
      ) {
        setErrordateDosis2("Las dosis presentan fechas incoherentes");
        // setErrordateDosis2(null);
      } else if (
        values.provincia4 !== "" &&
        dateValues.selectedDate3 >= dateValues.selectedDate4
      ) {
        setErrordateDosis2("Las dosis presentan fechas incoherentes");
      } else {
        setErrordateDosis2(null);
        //setErrordateDosis3(null);
        return true;
      }
    } else {
      setErrordateDosis2(null);
      //setErrordateDosis3(null);
      return true;
    }
  };

  const IsFecha1 = () => {
    if (
      dateValues.selectedDate1 !== null &&
      validator.isDate(dateValues.selectedDate1)
    ) {
      const date = new Date();
      const NacimitnyoCon = new Date(
        dateValues.selectedDate1.toISOString().substring(0, 10)
      );

      if (NacimitnyoCon.getTime() > date.getTime()) {
        return false;
      } else {
        return true;
      }
    }
  };

  const IsFecha2 = () => {
    if (
      dateValues.selectedDate2 !== null &&
      validator.isDate(dateValues.selectedDate2)
    ) {
      const date = new Date();
      const NacimitnyoCon = new Date(
        dateValues.selectedDate2.toISOString().substring(0, 10)
      );

      if (NacimitnyoCon.getTime() > date.getTime()) {
        return false;
      } else {
        return true;
      }
    }
  };

  const IsFecha3 = () => {
    if (
      dateValues.selectedDate3 !== null &&
      validator.isDate(dateValues.selectedDate3) &&
      values.provincia3 !== ""
    ) {
      const date = new Date();
      const NacimitnyoCon = new Date(
        dateValues.selectedDate3.toISOString().substring(0, 10)
      );

      if (NacimitnyoCon.getTime() > date.getTime()) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const IsFecha4 = () => {
    if (
      dateValues.selectedDate4 !== null &&
      validator.isDate(dateValues.selectedDate4) &&
      values.provincia4 !== ""
    ) {
      const date = new Date();
      const NacimitnyoCon = new Date(
        dateValues.selectedDate4.toISOString().substring(0, 10)
      );

      if (NacimitnyoCon.getTime() > date.getTime()) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const handleGroupChange = (name) => {
    const event = {
      target: {
        name,
        value: "",
      },
    };
    if (name === "cedula1") {
      dispatch(DeleteCedula1());
      setErrorDocIdent1(null);
    }
    if (name === "cedula2") {
      dispatch(DeleteCedula2());
      setErrorDocIdent2(null);
    }
    if (name === "cedula3") {
      dispatch(DeleteCedula3());
      setErrorDocIdent3(null);
    }
    if (name === "cedula4") {
      dispatch(DeleteCedula4());
      setErrorDocIdent4(null);
    }

    handleVaccineChange(event);
  };
  const handleCentroChange = (name, value) => {
    if (value === undefined) value = "";
    const event = {
      target: {
        name,
        value,
      },
    };
    handleCentros(event);
  };
  useEffect(() => {
    if (centrosValues.centro1) {
      centros1.forEach((centro) => {
        if (centro.center_name === centrosValues.centro1) {
          const event = {
            target: {
              name: "centro1",
              value: centro.center_id,
            },
          };
          handleVaccineChange(event);
        }
      });
    } else if (centrosValues.centro1 === "") {
      const event = {
        target: {
          name: "centro1",
          value: "",
        },
      };
      handleVaccineChange(event);
    }
  }, [centrosValues.centro1, centros1]);

  useEffect(() => {
    if (centrosValues.centro2) {
      centros2.forEach((centro) => {
        if (centro.center_name === centrosValues.centro2) {
          const event = {
            target: {
              name: "centro2",
              value: centro.center_id,
            },
          };
          handleVaccineChange(event);
        }
      });
    } else if (centrosValues.centro2 === "") {
      const event = {
        target: {
          name: "centro2",
          value: "",
        },
      };
      handleVaccineChange(event);
    }
  }, [centrosValues.centro2, centros2]);

  useEffect(() => {
    if (centrosValues.centro3) {
      centros3.forEach((centro) => {
        if (centro.center_name === centrosValues.centro3) {
          const event = {
            target: {
              name: "centro3",
              value: centro.center_id,
            },
          };
          handleVaccineChange(event);
        }
      });
    } else if (centrosValues.centro3 === "") {
      const event = {
        target: {
          name: "centro3",
          value: "",
        },
      };
      handleVaccineChange(event);
    }
  }, [centrosValues.centro3, centros3]);

  useEffect(() => {
    if (centrosValues.centro4) {
      centros4.forEach((centro) => {
        if (centro.center_name === centrosValues.centro4) {
          const event = {
            target: {
              name: "centro4",
              value: centro.center_id,
            },
          };
          handleVaccineChange(event);
        }
      });
    } else if (centrosValues.centro4 === "") {
      const event = {
        target: {
          name: "centro4",
          value: "",
        },
      };
      handleVaccineChange(event);
    }
  }, [centrosValues.centro4, centros4]);

  // *****PRIMERA DOSIS*****
  useGetCenter([
    values.municipio1,
    setCentros1,
    centrosValues.centro1,
    setErrorCentro1,
  ]);

  // *****SEGUNDA DOSIS*****
  useGetCenter([
    values.municipio2,
    setCentros2,
    centrosValues.centro2,
    setErrorCentro2,
  ]);

  // *****TERCERA DOSIS*****
  useGetCenter([
    values.municipio3,
    setCentros3,
    centrosValues.centro3,
    setErrorCentro3,
  ]);

  // *****CUARTA DOSIS*****
  useGetCenter([
    values.municipio4,
    setCentros4,
    centrosValues.centro4,
    setErrorCentro4,
  ]);

  const [errorCodigoTarjeta1, isCodigoTargetaValid1] = useValidateTarjeta(
    values.tarjeta1.toUpperCase(),
    "Formato incorrecto",
    CodigoTarjetaRegx
  );
  const [errorCodigoTarjeta2, isCodigoTargetaValid2] = useValidateTarjeta(
    values.tarjeta2.toUpperCase(),
    "Formato incorrecto",
    CodigoTarjetaRegx
  );
  const [errorCodigoTarjeta3, isCodigoTargetaValid3] = useValidateTarjeta(
    values.tarjeta3.toUpperCase(),
    "Formato incorrecto",
    CodigoTarjetaRegx
  );
  const [errorCodigoTarjeta4, isCodigoTargetaValid4] = useValidateTarjeta(
    values.tarjeta4.toUpperCase(),
    "Formato incorrecto",
    CodigoTarjetaRegx
  );

  const handleReset = () => {
    dispatch(SalveValuesChangeStep2({...GlobalFormValues, step3: {}}))
    vaccineValuesReset();
    datesReset();
    cedPasValuesReset();
    centrosReset();
    showHideReset();
  };

  // dose 1
  const cardCodeFieldProps1 = {
    classes,
    value: values.tarjeta1,
    handleChange: handleVaccineChange,
    validation: isCodigoTargetaValid1,
    error: errorCodigoTarjeta1,
    placeholder: "A-XXX-XXX-XXX",
    name: "tarjeta1",
    titleName: "Código de tarjeta de vacunación",
    disabled: false,
    mask: TarjectMask,
    maxLength: 13,
  };

  const dateFieldProps1 = {
    classes,
    value: dateValues.selectedDate1,
    handleChange: handleDateChange,
    validation: () => {
      IsValidatorFecha1();
      ValidateDateDosis();
    },
    error: Errorfecha1,
    placeholder: "dd/mm/aaaa",
    titleName: "Fecha de vacunación *",
    disabled: false,
    identidicationData: null,
    invalidDateMessage: "Formato incorrecto",
    maxDateMessage:
      "La fecha de vacunación no puede ser mayor a la fecha actual",
    minDateMessage: "Revise la fecha de vacunación",
    KeyboardButtonProps: {
      "aria-label": "change date",
    },
    InputLabelProps: {
      shrink: true,
    },
    InputProps: {
      className: classes.inputText,
    },
    titleClass: classes.type_color,
    name: "selectedDate1",
  };

  const tipeDocumentProps1 = {
    classes,
    value: cedPasValues.TipoIdent1,
    handleChange: handleCedPasChange,
    handleReset: handleGroupChange,
    validation: ValidateIsTipoDoc1,
    error: ErrorTipoDocIden1,
    name: "TipoIdent1",
    titleName:
      "Tipo de documento de identificación del adulto que acompañó al menor *",
    nameFields: "cedula1",
    valueControlCed: "CEDULA",
    labelControlCed: "Cédula",
    valueControlPas: "PASAPORTE",
    labelControlPas: "Pasaporte",
  };

  const fieldCedulaProps1 = {
    classes,
    value: values.cedula1,
    name: "cedula1",
    titleName: "Cédula del adulto que acompañó al menor *",
    handleChange: handleVaccineChange,
    placeholder: "000-0000000-0",
    validation: ValidatorDocIdent1,
    mask: TextMaskCustom,
    error: ErrorDocIdent1,
  };

  const fieldPassportProps1 = {
    classes,
    value: values.cedula1.toUpperCase(),
    name: "cedula1",
    titleName: "Pasaporte del adulto que acompañó al menor *",
    handleChange: handleVaccineChange,
    placeholder: "Ej.: RD123456",
    validation: ValidatorDocIdent1,
    error: ErrorDocIdent1,
  };

  const fieldNameProps1 = {
    classes,
    value: values.nombre1.toUpperCase(),
    titleName: "Nombre del adulto que acompañó al menor *",
    name: "nombre1",
    disabled: cedPasValues.TipoIdent1 === "CEDULA",
    placeholder: "Nombre del adulto",
    handleChange: handleVaccineChange,
    validation: IsValidateName1,
    identidicationData: values.cedula1,
    error: ErrorNombre1,
  };

  const fieldProvinceProps1 = {
    classes,
    name: "provincia1",
    titleName: "Provincia de aplicación *",
    value: values.provincia1,
    error: ErrorProvincia1,
    handleChange: (e) => {
      handleVaccineChange(e)
      resetTown("municipio1")
      resetCenter("centro1")
    },
    validation: ValidateIsProvincia1,
    provincias,
  };

  const fieldTownProps1 = {
    classes,
    name: "municipio1",
    titleName: "Municipio de aplicación *",
    value: values.municipio1,
    error: ErrorMunicipio1,
    handleChange: handleVaccineChange,
    validation: ValidateIsMunicipio1,
    province: values.provincia1,
    disabled: !values.provincia1,
    municipios,
  };

  const fieldCenterProps1 = {
    classes,
    titleName: "Centro de aplicación *",
    disabled: !values.municipio1,
    centros: centros1,
    validation: ValidateIsCentro1,
    value: centrosValues.centro1,
    handleChange: handleCentroChange,
    name: "centro1",
    placeholder: "Seleccione un centro",
    error1: errorCentro1,
    error2: ErrorCentro1,
  };

  // dose 2
  const cardCodeFieldProps2 = {
    classes,
    value: values.tarjeta2,
    className: "",
    handleChange: handleVaccineChange,
    validation: isCodigoTargetaValid2,
    error: errorCodigoTarjeta2,
    placeholder: "A-XXX-XXX-XXX",
    name: "tarjeta2",
    titleName: "Código de tarjeta de vacunación",
    disabled: false,
    mask: TarjectMask,
    readOnly: values.sameTarjectCode,
    maxLength: 13,
  };

  const dateFieldProps2 = {
    classes,
    value: dateValues.selectedDate2,
    handleChange: handleDateChange,
    validation: () => {
      IsValidatorFecha2();
      ValidateDateDosis();
    },
    error: Errorfecha2,
    placeholder: "dd/mm/aaaa",
    titleName: "Fecha de vacunación *",
    disabled: false,
    identidicationData: null,
    invalidDateMessage: "Formato incorrecto",
    maxDateMessage:
      "La fecha de vacunación no puede ser mayor a la fecha actual",
    minDateMessage: "Revise la fecha de vacunación",
    KeyboardButtonProps: {
      "aria-label": "change date",
    },
    InputLabelProps: {
      shrink: true,
    },
    InputProps: {
      className: classes.inputText,
    },
    titleClass: classes.type_color,
    name: "selectedDate2",
  };

  const tipeDocumentProps2 = {
    classes,
    value: cedPasValues.TipoIdent2,
    handleChange: handleCedPasChange,
    handleReset: handleGroupChange,
    validation: ValidateIsTipoDoc2,
    error: ErrorTipoDocIden2,
    name: "TipoIdent2",
    titleName:
      "Tipo de documento de identificación del adulto que acompañó al menor *",
    nameFields: "cedula2",
    valueControlCed: "CEDULA",
    labelControlCed: "Cédula",
    valueControlPas: "PASAPORTE",
    labelControlPas: "Pasaporte",
  };

  const fieldCedulaProps2 = {
    classes,
    value: values.cedula2,
    name: "cedula2",
    titleName: "Cédula del adulto que acompañó al menor *",
    handleChange: handleVaccineChange,
    placeholder: "000-0000000-0",
    validation: ValidatorDocIdent2,
    mask: TextMaskCustom,
    error: ErrorDocIdent2,
  };

  const fieldPassportProps2 = {
    classes,
    value: values.cedula2.toUpperCase(),
    name: "cedula2",
    titleName: "Pasaporte del adulto que acompañó al menor *",
    handleChange: handleVaccineChange,
    placeholder: "Ej.: RD123456",
    validation: ValidatorDocIdent2,
    error: ErrorDocIdent2,
  };

  const fieldNameProps2 = {
    classes,
    value: values.nombre2.toUpperCase(),
    titleName: "Nombre del adulto que acompañó al menor *",
    name: "nombre2",
    disabled: cedPasValues.TipoIdent2 === "CEDULA",
    placeholder: "Nombre del adulto",
    handleChange: handleVaccineChange,
    validation: IsValidateName2,
    identidicationData: values.cedula2,
    error: ErrorNombre2,
  };

  const fieldProvinceProps2 = {
    classes,
    name: "provincia2",
    titleName: "Provincia de aplicación *",
    value: values.provincia2,
    error: ErrorProvincia2,
    handleChange: (e) => {
      handleVaccineChange(e)
      resetTown("municipio2")
      resetCenter("centro2")
    },
    validation: ValidateIsProvincia2,
    provincias,
  };

  const fieldTownProps2 = {
    classes,
    name: "municipio2",
    titleName: "Municipio de aplicación *",
    value: values.municipio2,
    error: ErrorMunicipio2,
    handleChange: handleVaccineChange,
    validation: ValidateIsMunicipio2,
    province: values.provincia2,
    disabled: !values.provincia2,
    municipios,
  };

  const fieldCenterProps2 = {
    classes,
    titleName: "Centro de aplicación *",
    disabled: !values.municipio2,
    centros: centros2,
    validation: ValidateIsCentro2,
    value: centrosValues.centro2,
    handleChange: handleCentroChange,
    name: "centro2",
    placeholder: "Seleccione un centro",
    error1: errorCentro2,
    error2: ErrorCentro2,
  };

  // dose 3
  const cardCodeFieldProps3 = {
    classes,
    value: values.tarjeta3,
    handleChange: handleVaccineChange,
    validation: isCodigoTargetaValid3,
    error: errorCodigoTarjeta3,
    placeholder: "A-XXX-XXX-XXX",
    name: "tarjeta3",
    titleName: "Código de tarjeta de vacunación",
    disabled: !values.provincia3,
    mask: TarjectMask,
    readOnly: values.sameTarjectCode,
    maxLength: 13,
  };

  const dateFieldProps3 = {
    classes,
    value: dateValues.selectedDate3,
    handleChange: handleDateChange,
    validation: () => {
      IsValidatorFecha3();
      ValidateDateDosis();
    },
    error: Errorfecha3,
    placeholder: "dd/mm/aaaa",
    titleName: `Fecha de vacunación ${values.provincia3 === "" ? "" : "*"}`,
    disabled: !values.provincia3,
    identidicationData: null,
    invalidDateMessage: "Formato incorrecto",
    maxDateMessage:
      "La fecha de vacunación no puede ser mayor a la fecha actual",
    minDateMessage: "Revise la fecha de vacunación",
    KeyboardButtonProps: {
      "aria-label": "change date",
    },
    InputLabelProps: {
      shrink: true,
    },
    InputProps: {
      className: !values.provincia3 ? classes.borderStyle : classes.inputText,
    },
    titleClass: classes.type_color,
    name: "selectedDate3",
  };

  const tipeDocumentProps3 = {
    classes,
    value: cedPasValues.TipoIdent3,
    handleChange: handleCedPasChange,
    handleReset: handleGroupChange,
    validation: ValidateIsTipoDoc3,
    error: ErrorTipoDocIden3,
    name: "TipoIdent3",
    titleName: `Tipo de documento de identificación del adulto que acompañó al menor ${
      values.provincia3 && "*"
    }`,
    nameFields: "cedula3",
    valueControlCed: "CEDULA",
    labelControlCed: "Cédula",
    valueControlPas: "PASAPORTE",
    labelControlPas: "Pasaporte",
  };

  const fieldCedulaProps3 = {
    classes,
    value: values.cedula3,
    name: "cedula3",
    titleName: `Cédula del adulto que acompañó al menor ${
      values.provincia3 && "*"
    }`,
    handleChange: handleVaccineChange,
    placeholder: "000-0000000-0",
    validation: ValidatorDocIdent3,
    mask: TextMaskCustom,
    error: ErrorDocIdent3,
    disabled: !values.provincia3,
  };

  const fieldPassportProps3 = {
    classes,
    value: values.cedula3.toUpperCase(),
    name: "cedula3",
    titleName: `Pasaporte del adulto que acompañó al menor ${
      values.provincia3 && "*"
    }`,
    handleChange: handleVaccineChange,
    placeholder: "Ej.: RD123456",
    validation: ValidatorDocIdent3,
    error: ErrorDocIdent3,
    disabled: !values.provincia3,
  };

  const fieldNameProps3 = {
    classes,
    value: values.nombre3.toUpperCase(),
    titleName: `Nombre del adulto que acompañó al menor ${
      values.provincia3 && "*"
    }`,
    name: "nombre3",
    disabled: cedPasValues.TipoIdent3 === "CEDULA" || !values.provincia3,
    placeholder: "Nombre del adulto",
    handleChange: handleVaccineChange,
    validation: IsValidateName3,
    identidicationData: values.cedula3,
    error: ErrorNombre3,
  };

  const fieldProvinceProps3 = {
    classes,
    name: "provincia3",
    titleName: "Provincia de aplicación",
    value: values.provincia3,
    handleChange: (e) => {
      handleVaccineChange(e)
      resetTown("municipio3")
      resetCenter("centro3")
    },
    provincias,
  };

  const fieldTownProps3 = {
    classes,
    name: "municipio3",
    titleName: `Municipio de aplicación ${values.provincia3 && "*"}`,
    value: values.municipio3,
    error: ErrorMunicipio3,
    handleChange: handleVaccineChange,
    validation: ValidateIsMunicipio3,
    province: values.provincia3,
    disabled: !values.provincia3,
    municipios,
  };

  const fieldCenterProps3 = {
    classes,
    titleName: `Centro de aplicación ${values.provincia3 && "*"}`,
    disabled: !values.municipio3 || !values.provincia3,
    centros: centros3,
    validation: ValidateIsCentro3,
    value: centrosValues.centro3,
    handleChange: handleCentroChange,
    name: "centro3",
    placeholder: "Seleccione un centro",
    error1: errorCentro3,
    error2: ErrorCentro3,
  };

  // dose 4
  const cardCodeFieldProps4 = {
    classes,
    value: values.tarjeta4,
    handleChange: handleVaccineChange,
    validation: isCodigoTargetaValid4,
    error: errorCodigoTarjeta4,
    placeholder: "A-XXX-XXX-XXX",
    name: "tarjeta4",
    titleName: "Código de tarjeta de vacunación",
    disabled: !values.provincia3,
    mask: TarjectMask,
    readOnly: values.sameTarjectCode,
    maxLength: 13,
  };

  const dateFieldProps4 = {
    classes,
    value: dateValues.selectedDate4,
    handleChange: handleDateChange,
    validation: () => {
      IsValidatorFecha4();
      ValidateDateDosis();
    },
    error: Errorfecha4,
    placeholder: "dd/mm/aaaa",
    titleName: `Fecha de vacunación ${values.provincia4 === "" ? "" : "*"}`,
    disabled: !values.provincia3,
    identidicationData: null,
    invalidDateMessage: "Formato incorrecto",
    maxDateMessage:
      "La fecha de vacunación no puede ser mayor a la fecha actual",
    minDateMessage: "Revise la fecha de vacunación",
    KeyboardButtonProps: {
      "aria-label": "change date",
    },
    InputLabelProps: {
      shrink: true,
    },
    InputProps: {
      className: !values.provincia3 ? classes.borderStyle : classes.inputText,
    },
    titleClass: classes.type_color,
    name: "selectedDate4",
  };

  const tipeDocumentProps4 = {
    classes,
    value: cedPasValues.TipoIdent4,
    handleChange: handleCedPasChange,
    handleReset: handleGroupChange,
    validation: ValidateIsTipoDoc4,
    error: ErrorTipoDocIden4,
    name: "TipoIdent4",
    titleName: `Tipo de documento de identificación del adulto que acompañó al menor ${
      values.provincia4 && "*"
    }`,
    nameFields: "cedula4",
    valueControlCed: "CEDULA",
    labelControlCed: "Cédula",
    valueControlPas: "PASAPORTE",
    labelControlPas: "Pasaporte",
  };

  const fieldCedulaProps4 = {
    classes,
    value: values.cedula4,
    name: "cedula4",
    titleName: `Cédula del adulto que acompañó al menor ${
      values.provincia4 && "*"
    }`,
    handleChange: handleVaccineChange,
    placeholder: "000-0000000-0",
    validation: ValidatorDocIdent4,
    mask: TextMaskCustom,
    error: ErrorDocIdent4,
    disabled: !values.provincia4,
  };

  const fieldPassportProps4 = {
    classes,
    value: values.cedula4.toUpperCase(),
    name: "cedula4",
    titleName: `Pasaporte del adulto que acompañó al menor ${
      values.provincia4 && "*"
    }`,
    handleChange: handleVaccineChange,
    placeholder: "Ej.: RD123456",
    validation: ValidatorDocIdent4,
    error: ErrorDocIdent4,
    disabled: !values.provincia4,
  };

  const fieldNameProps4 = {
    classes,
    value: values.nombre4.toUpperCase(),
    titleName: `Nombre del adulto que acompañó al menor ${
      values.provincia4 && "*"
    }`,
    name: "nombre4",
    disabled: cedPasValues.TipoIdent4 === "CEDULA" || !values.provincia4,
    placeholder: "Nombre del adulto",
    handleChange: handleVaccineChange,
    validation: IsValidateName4,
    identidicationData: values.cedula4,
    error: ErrorNombre4,
  };

  const fieldProvinceProps4 = {
    classes,
    name: "provincia4",
    titleName: "Provincia de aplicación",
    value: values.provincia4,
    handleChange: (e) => {
      handleVaccineChange(e)
      resetTown("municipio4")
      resetCenter("centro4")
    },
    disabled: !values.provincia3,
    provincias,
  };

  const fieldTownProps4 = {
    classes,
    name: "municipio4",
    titleName: `Municipio de aplicación ${values.provincia4 && "*"}`,
    value: values.municipio4,
    error: ErrorMunicipio4,
    handleChange: handleVaccineChange,
    validation: ValidateIsMunicipio4,
    province: values.provincia4,
    disabled: !values.provincia4,
    municipios,
  };

  const fieldCenterProps4 = {
    classes,
    titleName: `Centro de aplicación ${values.provincia4 && "*"}`,
    disabled: !values.municipio4 || !values.provincia4,
    centros: centros4,
    validation: ValidateIsCentro4,
    value: centrosValues.centro4,
    handleChange: handleCentroChange,
    name: "centro4",
    placeholder: "Seleccione un centro",
    error1: errorCentro4,
    error2: ErrorCentro4,
  };

  const handleNext = () => {
    const bandera = {
      type: "Enviar",
    };
    if (
      IsValidatorFecha1() &
      IsValidatorFecha2() &
      IsValidatorFecha3() &
      IsValidatorFecha4() &
      IsFecha1() &
      IsFecha2() &
      IsFecha3() &
      IsFecha4() &
      ValidateDateDosis() &
      ValidateIsProvincia1() &
      ValidateIsProvincia2() &
      ValidateIsMunicipio1() &
      ValidateIsMunicipio2() &
      ValidateIsMunicipio3() &
      ValidateIsMunicipio4() &
      ValidateIsCentro1() &
      ValidateIsCentro2() &
      ValidateIsCentro3() &
      ValidateIsCentro4() &
      IsValidateName1() &
      IsValidateName2() &
      IsValidateName3() &
      IsValidateName4() &
      ValidateIsTipoDoc1() &
      ValidateIsTipoDoc2() &
      ValidateIsTipoDoc3() &
      ValidateIsTipoDoc4() &
      ValidatorDocIdent1(bandera) &
      ValidatorDocIdent2(bandera) &
      ValidatorDocIdent3(bandera) &
      ValidatorDocIdent4(bandera) &
      isCodigoTargetaValid1() &
      isCodigoTargetaValid2() &
      isCodigoTargetaValid3() &
      isCodigoTargetaValid4()
    ) {
      // dispatch(
      //   SalveValuesChangeStep2({ ...GlobalFormValues, step3: { ...values } })
      // );
      let completeForm = {
        ...GlobalFormValues,
        step3: {
          ...values,
          centros: { ...centrosValues },
          idTypes: { ...cedPasValues },
          dates: dateValues,
        },
      }
      console.log(completeForm);
      dispatch(RequestDataFormMenores(completeForm))
      .then(() => {
        dispatch(DeleteRequestVerifyDocIndent());
        dispatch(SetActiveStep(CountState + 1));
      });
    }
  };

  const handleBack = () => {
    dispatch(
      SalveValuesChangeStep2({
        ...GlobalFormValues,
        step3: {
          ...values,
          centros: { ...centrosValues },
          idTypes: { ...cedPasValues },
          dates: dateValues,
        },
      })
    );
    dispatch(SetActiveStep(CountState - 1));
  };

  return (
    <Box>
      {/* provincia */}
      <Grid container justifyContent="flex-end">
        <Typography
          onClick={handleReset}
          align="right"
          className={classes.reset}
        >
          Limpiar campos
        </Typography>
      </Grid>
      <Box className={classes.box_form}>
        <Typography
          style={{ marginTop: "1rem" }}
          className={classes.sub_title}
          variant="h6"
        >
          Primera dosis
        </Typography>

        {showHideValues.show1 && (
          <>
            {/* provincia */}
            <ProvinceField {...fieldProvinceProps1} />

            {/* municipio */}
            <TownField {...fieldTownProps1} />

            {/* centro */}
            <CenterField {...fieldCenterProps1} />

            {/* Fecha */}
            <DateField {...dateFieldProps1} />

            <TipeDocument {...tipeDocumentProps1} />
            {cedPasValues.TipoIdent1 === "CEDULA" && (
              <FieldIdentification {...fieldCedulaProps1} />
            )}
            {cedPasValues.TipoIdent1 === "PASAPORTE" && (
              <FieldIdentification {...fieldPassportProps1} />
            )}
            <FieldName {...fieldNameProps1} />

            <FormControl className={classes.select}>
              <FormControlLabel
                className={classes.type_color2}
                control={
                  <Checkbox
                    checked={values.sameTutorInfo}
                    onChange={handleVaccineChange}
                    name="sameTutorInfo"
                  />
                }
                label="Aplicar este Tutor a las demás dosis"
              />
            </FormControl>

            {/* Codigo de tarjeta de vacunación */}
            <CardCodeField {...cardCodeFieldProps1} />

            <FormControl className={classes.select}>
              <FormControlLabel
                className={classes.type_color2}
                control={
                  <Checkbox
                    checked={values.sameTarjectCode}
                    onChange={handleVaccineChange}
                    name="sameTarjectCode"
                  />
                }
                label="Aplicar este código a las demás dosis"
              />
            </FormControl>
          </>
        )}
        <Box
          className={classes.show_hide_box}
          data-name="show1"
          onClick={handleShowHide}
        >
          <Typography className={classes.show_text}>
            {showHideValues.show1 ? "Ocultar campos" : "Mostrar campos"}
          </Typography>
          {showHideValues.show1 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Box>
      </Box>
      {/* provincia */}
      <Box className={classes.box_form}>
        <Typography
          style={{ marginTop: "1rem" }}
          className={classes.sub_title}
          variant="h6"
        >
          Segunda dosis
        </Typography>

        {showHideValues.show2 && (
          <>
            {/* provincia */}
            <ProvinceField {...fieldProvinceProps2} />

            {/* municipio */}
            <TownField {...fieldTownProps2} />

            {/* centro */}
            <CenterField {...fieldCenterProps2} />

            {/* Fecha */}
            <DateField {...dateFieldProps2} />

            {!values.sameTutorInfo && (
              <>
                <TipeDocument {...tipeDocumentProps2} />
                {cedPasValues.TipoIdent2 === "CEDULA" && (
                  <FieldIdentification {...fieldCedulaProps2} />
                )}
                {cedPasValues.TipoIdent2 === "PASAPORTE" && (
                  <FieldIdentification {...fieldPassportProps2} />
                )}
                <FieldName {...fieldNameProps2} />
              </>
            )}

            {/* Codigo de tarjeta de vacunación */}
            {!values.sameTarjectCode && (
              <>
                <CardCodeField {...cardCodeFieldProps2} />
              </>
            )}
          </>
        )}
        <Box
          className={classes.show_hide_box}
          data-name="show2"
          onClick={handleShowHide}
        >
          <Typography className={classes.show_text}>
            {showHideValues.show2 ? "Ocultar campos" : "Mostrar campos"}
          </Typography>
          {showHideValues.show2 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Box>
      </Box>

      <Box className={classes.box_form}>
        <Typography
          style={{ marginTop: "1rem" }}
          className={classes.sub_title}
          variant="h6"
        >
          Tercera dosis
        </Typography>

        {showHideValues.show3 && (
          <>
            {/* provincia */}
            <ProvinceField {...fieldProvinceProps3} />

            {/* municipio */}
            <TownField {...fieldTownProps3} />

            {/* centro */}
            <CenterField {...fieldCenterProps3} />

            {/* Fecha */}
            <DateField {...dateFieldProps3} />

            {!values.sameTutorInfo && (
              <>
                <TipeDocument {...tipeDocumentProps3} />
                {cedPasValues.TipoIdent3 === "CEDULA" && (
                  <FieldIdentification {...fieldCedulaProps3} />
                )}
                {cedPasValues.TipoIdent3 === "PASAPORTE" && (
                  <FieldIdentification {...fieldPassportProps3} />
                )}
                <FieldName {...fieldNameProps3} />
              </>
            )}

            {/* Codigo de tarjeta de vacunación */}
            {!values.sameTarjectCode && (
              <>
                <CardCodeField {...cardCodeFieldProps3} />
              </>
            )}
          </>
        )}
        <Box
          className={classes.show_hide_box}
          data-name="show3"
          onClick={handleShowHide}
        >
          <Typography className={classes.show_text}>
            {showHideValues.show3 ? "Ocultar campos" : "Mostrar campos"}
          </Typography>
          {showHideValues.show3 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Box>
      </Box>
      <Box className={classes.box_form}>
        <Typography
          style={{ marginTop: "1rem" }}
          className={classes.sub_title}
          variant="h6"
        >
          Cuarta dosis
        </Typography>

        {showHideValues.show4 && (
          <>
            {/* provincia */}
            <ProvinceField {...fieldProvinceProps4} />

            {/* municipio */}
            <TownField {...fieldTownProps4} />

            {/* centro */}
            <CenterField {...fieldCenterProps4} />

            {/* Fecha */}
            <DateField {...dateFieldProps4} />

            {!values.sameTutorInfo && (
              <>
                <TipeDocument {...tipeDocumentProps4} />
                {cedPasValues.TipoIdent4 === "CEDULA" && (
                  <FieldIdentification {...fieldCedulaProps4} />
                )}
                {cedPasValues.TipoIdent4 === "PASAPORTE" && (
                  <FieldIdentification {...fieldPassportProps4} />
                )}
                <FieldName {...fieldNameProps4} />
              </>
            )}

            {/* Codigo de tarjeta de vacunación */}
            {!values.sameTarjectCode && (
              <>
                <CardCodeField {...cardCodeFieldProps4} />
              </>
            )}
          </>
        )}
        <Box
          className={classes.show_hide_box}
          data-name="show4"
          onClick={handleShowHide}
        >
          <Typography className={classes.show_text}>
            {showHideValues.show4 ? "Ocultar campos" : "Mostrar campos"}
          </Typography>
          {showHideValues.show4 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Box>
      </Box>
      {ErrordateDosis2 && (
        <Alert severity="error">
          <AlertTitle>{ErrordateDosis2}</AlertTitle>
        </Alert>
      )}
      <Box
        my={2}
        display="flex"
        justifyContent="space-around"
        className={classes.buttons_content}
      >
        <Button
          className={classes.btn_back}
          variant="contained"
          onClick={handleBack}
        >
          Ir atrás
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          onClick={handleNext}
        >
          Enviar
        </Button>
      </Box>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress size={100} color="inherit" />
      </Backdrop>
    </Box>
  );
};
