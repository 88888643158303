import { makeStyles } from "@material-ui/core/styles";
import bgImage from "../../assets/images/background_image.png";

export const useStyles = makeStyles((theme) => ({
  main: {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    display: "flex",
    "@media screen and (max-width: 550px)": {
      background: "white",
    },
  },
  container: {
    margin: "9vh auto",
    backgroundColor: "#fff",
    borderRadius: "0.5rem",
    minHeight: "calc(100vh - 18vh)",
    maxWidth: "610px",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexDirection: "column",
    "@media screen and (max-width: 600px)": {
      width: "auto",
      margin: "9vh 1rem 9vh 1rem",
    },
    "@media screen and (max-width: 550px)": {
      width: "100%",
      margin: "auto",
      borderRadius: "0px",
    },
  },
  modal_error:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  modal_box:{
    position: "relative",
    maxWidth: "450px",
    width: "80%",
    margin: "1rem",
    padding: "2rem",
    minHeight: "180px",
    backgroundColor: "#FDECEA",
    borderRadius: "0.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // border: "2px solid #611A15",
    flexDirection: "column",
  },
  modal_text: {
    fontWeight: "bold",
    marginTop: "1.5rem",
    color: "#611A15"
  },
  close_icon:{
    position: "absolute",
    top: "15px",
    right: "15px",
    transform: "scale(1.5)",
    cursor: "pointer",
    "&:hover":{
      animation: "$rotate .4s ease-in-out",
    },
    color: "#611A15"
  },
  error_icon:{
    color: "#F55448",
    transform: "scale(3)",
  },
  "@keyframes rotate":{
    "100%":{
      transform: "rotate(90deg) scale(1.5)"
    }
  },
}));
