import {
  error500,
  finishLoading,
  setError,
  startLoading,
} from "./UIError";
import {
  SenDataFormAdultosConCedula,
  SendDataFormAdultosSinCedula,
  SendDataFormMenores,
} from "../../Axios/sendForm";
import { TYPES } from "../../types/types";

export const RequestDataFormConCedula = (values, DataCorreo, DataPhone) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(startLoading());
      SenDataFormAdultosConCedula(values, DataCorreo, DataPhone)
        .then((data) => {
          dispatch(finishLoading());
          resolve(data.data);
          const message = data.data.message;
          const statusCode = data.data.statusCode;
          dispatch(SalveDataRequest(message, statusCode));
        })
        .catch((error) => {
          dispatch(finishLoading());
          const { message, statusCode } = JSON.parse(error.request.response);
          if(statusCode === 500){
            dispatch(error500(""))
          }
          reject(message);
        });
    });
  };
};

export const RequestDataFormAdultSinCedula = (values, PassportData) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(startLoading());
      SendDataFormAdultosSinCedula(values, PassportData)
        .then(({ data }) => {
          dispatch(finishLoading());
          resolve(data);
          const message = data.message;
          const statusCode = data.statusCode;
          dispatch(SalveDataRequest(message, statusCode));
        })
        .catch((error) => {
          dispatch(finishLoading());
          const { message, statusCode } = JSON.parse(error.request.response);
          if(statusCode === 500){
            dispatch(error500(""))
          }
          reject(message);
        });
    });
  };
};

export const RequestDataFormMenores = (formValues) => {
  return (dispatch) => {
    return new Promise((res, rej) => {
      dispatch(startLoading());
      SendDataFormMenores(formValues)
        .then((data) => {
          console.log(data);
          dispatch(finishLoading());
          const statusCode = data.status;
          const message = data.data.message;
          dispatch(SalveDataRequest(message, statusCode));
          res(true);
        })
        .catch((error) => {
          dispatch(finishLoading());
          const { message, statusCode } = JSON.parse(error.request.response);
          if(statusCode === 500){
            dispatch(error500(""))
          }
          dispatch(setError(message));
          res(true);
        });
    });
  };
};

export const SalveDataRequest = (message, statusCode) => ({
  type: TYPES.SaveAdultSinCedula,
  payload: {
    message,
    statusCode,
  },
});

export const borrarDataRequest = () => ({
  type: TYPES.RemoveAdultSinCedula,
});
