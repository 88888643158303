import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
function PrivareRoute({ component: Component, ...rest }) {
  const { provincias } = useSelector((state) => state.CountryData);
  return (
    <div>
      <Route {...rest}>
        {provincias ? <Component /> : <Redirect to={"/"} />}
      </Route>
    </div>
  );
}

export default PrivareRoute;
