import { useState } from "react";

export const useValidatorNames = (value, message, extraValues = {}) => {
  const [msgError, setMsgError] = useState(null);

  const basicValidation = () => {
    if (!value) {
      setMsgError(message);
      return false;
    }
    if (extraValues.regx) {
      if (!extraValues.regx.test(value)) {
        setMsgError(extraValues.msg);
        return false;
      }
    }
    if (value.length < 2) {
      setMsgError("Este campo debe tener al menos 2 caracteres");
      return false;
    }
    setMsgError(null);
    return true;
  };

  const verify = () => {
    if(extraValues?.free) return true
    if (Object.keys(extraValues).includes("dependence")) {
      if (extraValues.dependence) {
        return basicValidation();
      }
      return true;
    } else {
      return basicValidation();
    }
  };

  return [msgError, verify];
};
