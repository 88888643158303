import React from "react";
import { FormControl, OutlinedInput, Typography, Grid } from "@material-ui/core";

export const FieldName = (props) => {
  const {
    classes,
    value,
    inputRef,
    handleChange,
    validation,
    error,
    placeholder,
    name,
    titleName,
    disabled,
    identidicationData
  } = props;

  return (
    <FormControl className={classes.select}>
      <label>
        <Typography align="left" className={classes.type_color} variant="h6">
          { titleName }
        </Typography>
      </label>
      <OutlinedInput
        name={name}
        value={value}
        inputRef={inputRef}
        onChange={handleChange}
        onBlur={validation}
        onKeyUp={validation}
        placeholder={placeholder}
        disabled={disabled}
        className={ disabled ? classes.borderStyle : ""}
      />
      { (identidicationData === null ||  identidicationData === undefined) && error && (
        <Grid>
          <Typography align="left" className={classes.MsgError}>
            {error}
          </Typography>
        </Grid>
      )}
    </FormControl>
  );
};
