import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";

import { Alert, AlertTitle } from "@material-ui/lab";
import { useStyles } from "../FormStepperStyles";
import { useDispatch, useSelector } from "react-redux";
import EmailInputField from "../../campos/EmailInputField";
import PhoneNumberField from "../../campos/PhoneNumberField";
import {
  RemoveGlobalDataValue,
  SalveValuesChangeStep,
  SetActiveStep,
} from "../../../Redux/actions/ActionGlobalState";
import { useForm } from "../../../hooks/useForm";
import { useState } from "react";
import { useValidator } from "../../../hooks/useValidator";
import validator from "validator/es";
import { RequestDataFormAdultSinCedula } from "../../../Redux/actions/ActionDataForm";
import { setError, startLoading } from "../../../Redux/actions/UIError";
import { DeleteRequestVerifyDocIndent } from "../../../Redux/actions/ActionCheckDocIdent";

export const Step2 = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { StatusDocIdent, PassportData } = useSelector(
    (state) => state.VerifyDocident
  );
  const { CountState, GlobalvaluesData } = useSelector(
    (state) => state.GlobalStateAPP
  );
  const { loading } = useSelector((state) => state.uiError);
  const [ErrorPhoneNumber, setErrorPhoneNumber] = useState(null);
  const [values, handleInputChange, setValues] = useForm({
    telefono: [GlobalvaluesData?.telefono ?? "", ""],
    correo: [GlobalvaluesData?.correo ?? "", ""],
  });
  const { telefono, correo } = values;
  const RegetEmail = new RegExp(/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/);
  const RegetPhoneNumber = new RegExp(/^[\d*]{10,15}$/);
  const handleReset = () => {
    if (!PassportData) {
      const changeName = {
        ...values,
        telefono: "",
        correo: "",
      };
      setValues(changeName);
    }
  };

  const handleNext = () => {
    if (IsValidatePhoneNumber() & isValidatorEmail()) {
      const UpdateValue = {
        ...GlobalvaluesData,
        telefono: telefono,
        correo: correo,
      };
      dispatch(RequestDataFormAdultSinCedula(UpdateValue, PassportData))
        .then((data) => {
          dispatch(startLoading());
          window.open(data.twoFaceLink, "_self");
          dispatch(DeleteRequestVerifyDocIndent());
          dispatch(RemoveGlobalDataValue());
        })
        .catch((error) => {
          dispatch(setError(error));
        });
    }
  };

  const handleBack = () => {
    const UpdateValue = {
      ...GlobalvaluesData,
      telefono: telefono,
      correo: correo,
    };
    StatusDocIdent === 400 && dispatch(SalveValuesChangeStep(UpdateValue));
    dispatch(SetActiveStep(CountState - 1));
  };

  //Validaciones Step 2
  const IsValidatePhoneNumber = () => {
    if (!validator.isEmpty(telefono)) {
      if (!RegetPhoneNumber.test(telefono)) {
        setErrorPhoneNumber("Formato incorrecto");
        return false;
      } else {
        setErrorPhoneNumber(null);
        return true;
      }
    } else {
      setErrorPhoneNumber(null);
      return true;
    }
  };
  const [ErrorEmail, isValidatorEmail] = useValidator(
    correo,
    "Campo requerido",
    { regx: RegetEmail, msg: "Formato incorrecto" }
  );

  // Componentes Globales Definicion
  const emailFieldProps = {
    classes,
    value: correo,
    handleChange: handleInputChange,
    validation: isValidatorEmail,
    error: ErrorEmail,
    placeholder: "ejemplo@gmail.com",
    name: "correo",
    titleName: "Correo electrónico *",
    disabled: !!PassportData?.email,
    identidicationData: PassportData,
  };

  const phoneNumberFieldProps = {
    classes,
    value: telefono,
    handleChange: handleInputChange,
    validation: IsValidatePhoneNumber,
    error: ErrorPhoneNumber,
    placeholder: "Ej.: 8295555555",
    name: "telefono",
    titleName: "Número de teléfono",
    disabled: !!PassportData?.phone,
  };

  return (
    <Box>
      <Alert severity="info">
        <AlertTitle>
          Por esta vía se le enviará instrucciones para obtener su Certificado
          de Vacunación.
        </AlertTitle>
      </Alert>
      {(PassportData === null) | (PassportData === undefined) && (
        <Grid container justifyContent="flex-end">
          <Typography
            onClick={handleReset}
            align="right"
            className={classes.reset}
          >
            Limpiar campos
          </Typography>
        </Grid>
      )}

      <EmailInputField {...emailFieldProps} />
      <PhoneNumberField {...phoneNumberFieldProps} />

      <Box
        my={2}
        display="flex"
        justifyContent="space-around"
        className={classes.buttons_content}
      >
        <Button
          className={classes.btn_back}
          variant="contained"
          onClick={handleBack}
        >
          Ir atrás
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          onClick={handleNext}
        >
          Enviar
        </Button>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress size={100} color="inherit" />
        </Backdrop>
      </Box>
    </Box>
  );
};
