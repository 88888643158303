import {
    FormControl,
    Grid,
    OutlinedInput,
    Typography
  } from "@material-ui/core";

const EmailInputField = (props) => {

    const {
        classes,
        value,
        handleChange,
        validation,
        error,
        placeholder,
        name,
        titleName,
        disabled,
        identidicationData
      } = props;


    return (
        <FormControl className={classes.select}>
        <label>
          <Typography align="left" className={classes.type_color} variant="h6">
            {titleName}
          </Typography>
        </label>
        <OutlinedInput
            disabled={disabled}
            name={name}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            onBlur={validation}
            onKeyUp={validation}
            className={disabled ? classes.borderStyle: ''}
        />

        {(identidicationData===null || identidicationData===undefined) && error && (
            <Grid>
              <Typography align="left" className={classes.MsgError}>
                {error}
              </Typography>
            </Grid>
        )}
      </FormControl>
    )
}

export default EmailInputField;