import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footer: {
    textAlign:'center',
    color: "#fff",
    background: "#00205C",
    padding: "20px",
    "& a": {
      color: "#fff",
    },
  },
  copyright: {
    margin: "20px 0px",
  },
  footer_title:{
    fontWeight: "bold",
    margin: "1rem"
  }
}));

export default useStyles;