import {TYPES} from "../../types/types";
const initialState ={
    StatusDocIdent:"",
    PassportData:"",
    MessageDocIdent:"",
    cedulaTutor:"",
}

export const CheckDocIdentReducer=(state={initialState}, action)=>{
 switch (action.type){
     case TYPES.SaveRequestVerifyDoc:
         return {
             ...state,
             StatusDocIdent : action.payload.StatusDocIdent,
             PassportData: action.payload.PassportData,
             MessageDocIdent: action.payload.MessageDocIdent

         };

     case TYPES.SavecedulaTutor:
         return {
             ...state,
             status:action.payload.status,
          cedulaTutor: action.payload.cedula
         }



     case TYPES.SaveCedula1:
         return {
             ...state,
             status:action.payload.status,
             cedula1: action.payload.cedula
         }

     case  TYPES.RemoveSaveCedula1:{
         return {
             ...state,
             cedula1: null
         }
     }

     case TYPES.SaveCedula2:
         return {
             ...state,
             status:action.payload.status,
             cedula2: action.payload.cedula
         }

     case  TYPES.RemoveSaveCedula2:{
         return {
             ...state,
             cedula2: null
         }
     }

     case TYPES.SaveCedula3:
         return {
             ...state,
             status:action.payload.status,
             cedula3: action.payload.cedula
         }

     case  TYPES.RemoveSaveCedula3:{
         return {
             ...state,
             cedula3: null
         }
     }

     case TYPES.SaveCedula4:
         return {
             ...state,
             status:action.payload.status,
             cedula4: action.payload.cedula
         }

     case  TYPES.RemoveSaveCedula4:{
         return {
             ...state,
             cedula4: null
         }
     }

     case TYPES.RemoveRequestVerifyDoc:
         return {

         };

     default:
         return state;






 }




}
